<template>
  <nav class="mt-10 text-sm" aria-label="quick links">
    <div class="-my-1 flex justify-start gap-x-6">
      <router-link
        class="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm hover:text-blue-600 dark:text-slate-200 dark:hover:text-blue-600 transition-all duration-150"
        to="/services"
        >Послуги</router-link
      >
      <router-link
        class="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm hover:text-blue-600 dark:text-slate-200 dark:hover:text-blue-600 transition-all duration-150"
        to="/shop"
        >Товари</router-link
      >
      <router-link
        class="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm hover:text-blue-600 dark:text-slate-200 dark:hover:text-blue-600 transition-all duration-150"
        to="/documentations"
        >Документація</router-link
      >
      <router-link
        class="relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm hover:text-blue-600 dark:text-slate-200 dark:hover:text-blue-600 transition-all duration-150"
        to="/contact"
        >Контакти</router-link
      >
    </div>
  </nav>
</template>
