<template>
    <Transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
        <div class="absolute left-1/2 z-10 flex w-screen max-w-max -translate-x-1/2 px-4 transition-all duration-150">
            <div
                class="mt-5 w-screen max-w-5xl flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                <div class="p-4 grid grid-cols-3 gap-x-6">
                    <router-link v-for="product in categoryProductsData" :key="product.id" :to="{
                        path: '/shop',
                        query: { myParam: product.id, myParam_h: product.header },
                    }" class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-100">
                        <div
                            class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:scale-105 transition duration-150">
                            <img class="h-8 w-8 " :src="product.icon" :alt="product.name" />
                        </div>
                        <div>
                            <h3 class="font-semibold text-[#142251] group-hover:text-blue-600 transition duration-150">
                                {{ product.name }}
                                <span class="absolute inset-0"></span>
                            </h3>
                            <p class="mt-1 text-slate-700 font-normal">{{ product.title }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                    <router-link @click="setShowDropdownProducts(false)" to="/documentations"
                        class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-slate-900 hover:bg-gray-100">
                        <svg class="h-5 w-5 flex-none text-slate-400" viewBox="0 0 20 20" fill="currentColor"
                            aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm6.39-2.908a.75.75 0 01.766.027l3.5 2.25a.75.75 0 010 1.262l-3.5 2.25A.75.75 0 018 12.25v-4.5a.75.75 0 01.39-.658z"
                                clip-rule="evenodd" />
                        </svg>
                        Документаціі та посібники
                    </router-link>
                    <router-link @click="setShowDropdownProducts(false)" to="/contact"
                        class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-slate-900 hover:bg-gray-100">
                        <svg class="h-5 w-5 flex-none text-slate-400" viewBox="0 0 20 20" fill="currentColor"
                            aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                                clip-rule="evenodd" />
                        </svg>
                        Зв'язатись з нами
                    </router-link>
                </div>
            </div>
        </div>
    </Transition>
</template>
  
<script>
// @ is an alias to /src
import { mapState, mapMutations } from "vuex";
export default {
    name: "DropDownProductHeaderMenu",
    components: {},
    data() {
        return {

        };
    },
    props: {

    },
    computed: {
        ...mapState([
            "categoryProductsData",
            "isPageActive",

        ]),
    },
    mounted() {

    },
    methods: {
        ...mapMutations(["setShowDropdownProducts"]),

    },
};
</script>
  
  