<template>
  <div class="relative">
    <div v-show="autorized" class="flex items-center">
      <button aria-label="Вихід з акаунту" @click="updateStateLogOut('true')"
        class="cursor-pointer hover:text-blue-600 dark:text-slate-200 dark:hover:text-blue-600 transition-all duration-150 text-primary">
        <LoginOut />
      </button>

      <!-- Большое Боковое меню авторизированого пользователя -->
      <Transition enter-from-class="-translate-x-full opacity-0" enter-active-class="transition duration-200"
        leave-to-class="-translate-x-full opacity-0" leave-active-class="transition duration-200">
        <div style="display: none" v-show="stateAcountModal"
          class="fixed text-sm left-16 top-0 h-full overflow-y-auto text-slate-900 border-r w-full sm:w-48 bg-slate-100 z-[104]">
          <div class="flex flex-col h-full w-full justify-between">
            <div class="">
              <div class="flex w-full py-[22px] px-3 items-center border-b">
                <div class="flex items-center h-9 w-full justify-between">
                  <div class="flex items-center">
                    <h5 class="font-medium">Вітаємо, {{ profileData.name }}</h5>
                  </div>
                  <div class="flex items-center">
                    <button @click="toggleAcount('false')"
                      class="text-slate-600 hover:text-slate-900 transition duration-150">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="p-2 space-y-8">
                <div class="mt-2">
                  <!-- Кнопка нового заказа -->
                  <router-link to="/crm/new-order"
                    class="inline-flex rounded-full px-4 py-1.5 text-sm leading-7 bg-blue-600 text-white hover:bg-blue-700 hover:text-slate-100 active:bg-blue-800 active:text-slate-300 justify-center transition-all duration-150 w-full text-center items-center">
                    <span>Нове замовлення</span>
                  </router-link>
                </div>
                <!-- Меню -->

                <div>
                  <ul class="text-slate-600 space-y-4 font-medium">
                    <li class="h-6 flex items-center">
                      <router-link to="/crm/orders"
                        class="flex w-full items-center hover:text-blue-600 transition duration-150">
                        <span>Замовлення</span>
                      </router-link>
                    </li>
                    <li class="h-6 flex items-center">
                      <router-link to="/crm/customers"
                        class="flex w-full items-center hover:text-blue-600 transition duration-150">
                        <span>Клієнти</span>
                      </router-link>
                    </li>
                    <li class="h-6 flex items-center">
                      <router-link to="/crm/objects"
                        class="flex w-full items-center hover:text-blue-600 transition duration-150">
                        <span>Об'єкти</span>
                      </router-link>
                    </li>
                    <li class="h-6 flex items-center">
                      <router-link to="/crm/finance"
                        class="flex w-full items-center hover:text-blue-600 transition duration-150">
                        <span>Фінанси</span>
                      </router-link>
                    </li>
                    <li class="h-6 flex items-center">
                      <router-link to="/crm/notes"
                        class="flex w-full items-center hover:text-blue-600 transition duration-150">
                        <span>Нотатки</span>
                      </router-link>
                    </li>
                    <li class="h-6 flex items-center">
                      <router-link to="/crm/testPage"
                        class="flex w-full items-center hover:text-blue-600 transition duration-150">
                        <span>Test Page</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <!-- Малое Боковое меню авторизированого пользователя -->
      <div class="hidden xl:block">
        <Transition enter-from-class="-translate-x-full opacity-0" enter-active-class="transition duration-200"
          leave-to-class="-translate-x-full opacity-0" leave-active-class="transition duration-200">
          <div style="display: none" v-show="autorized"
            :class="[stateAcountModal ? '' : 'border-r dark:border-slate-600']"
            class="fixed text-sm left-0 top-0 h-full overflow-y-auto text-slate-900 dark:text-slate-200 bg-slate-100 dark:bg-slate-800 w-full sm:w-16 z-[105]">
            <div class="flex flex-col h-full w-full justify-between">
              <div class="">
                <div class="flex py-[22px] px-3 items-center justify-center border-b dark:border-slate-600">
                  <div class="text-slate-400 h-9">
                    <div v-if="profileData.avatar">
                      <button @click="toggleAcount('true')" class="hover:scale-105 transition duration-150">
                        <div class="rounded-full overflow-hidden h-full">
                          <img class="h-full" :src="profileData.avatar" alt="" />
                        </div>
                      </button>
                    </div>
                    <div v-else>
                      <button @click="toggleAcount('true')" class="hover:scale-105 transition duration-150">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                          stroke="currentColor" class="w-full h-full">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="p-2 space-y-8">
                  <div class="text-center flex items-center justify-center mt-2">
                    <!-- Кнопка нового заказа -->
                    <router-link to="/crm/new-order"
                      class="p-2 rounded-full bg-blue-600 text-white hover:bg-blue-600/90 transition duration-150">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                      </span>
                    </router-link>
                  </div>
                  <!-- Меню -->

                  <div>
                    <ul class="text-slate-600 space-y-4">
                      <!-- Заказы -->
                      <li>
                        <router-link to="/crm/orders"
                          class="flex items-center justify-center hover:text-blue-600 dark:text-slate-200 dark:hover:text-blue-600 transition duration-150">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                              stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg>
                          </span>
                        </router-link>
                      </li>
                      <!-- Клиенты -->
                      <li>
                        <router-link to="/crm/customers"
                          class="flex items-center justify-center hover:text-blue-600 dark:text-slate-200 dark:hover:text-blue-600 transition duration-150">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                              stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                            </svg>
                          </span>
                        </router-link>
                      </li>
                      <!-- Обьекты -->
                      <li>
                        <router-link to="/crm/objects"
                          class="flex items-center justify-center hover:text-blue-600 dark:text-slate-200 dark:hover:text-blue-600 transition duration-150">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                              stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                            </svg>
                          </span>
                        </router-link>
                      </li>
                      <!-- Финансы -->
                      <li>
                        <router-link to="/crm/finance"
                          class="flex items-center justify-center hover:text-blue-600 dark:text-slate-200 dark:hover:text-blue-600 transition duration-150">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                              stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>
                          </span>
                        </router-link>
                      </li>
                      <!-- Заметки -->
                      <li>
                        <router-link to="/crm/notes"
                          class="flex items-center justify-center hover:text-blue-600 dark:text-slate-200 dark:hover:text-blue-600 transition duration-150">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                              stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                          </span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <!-- Курси валют -->
                  <div v-if="exchangeData.length != 0"
                    class="p-2 border-y dark:border-slate-600 text-xs text-center text-slate-600 space-y-2 dark:text-slate-200">
                    <div>
                      <div>{{ exchangeData[0].name }}</div>
                      <div class="font-medium">
                        {{ exchangeData[0].exchange }}
                      </div>
                    </div>
                    <div>
                      <div>{{ exchangeData[1].name }}</div>
                      <div class="font-medium">
                        {{ exchangeData[1].exchange }}
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center justify-center">
                    <ThemeSwitcher />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>

    <div v-show="!autorized" class="flex items-center">
      <button aria-label="Вихід до акаунту" @click="toggle" :class="[stateLogin ? 'text-blue-600' : 'text-primary']"
        class="cursor-pointer hover:text-blue-600 transition-all duration-150">
        <LoginIcon />
      </button>
    </div>

    <Transition enter-from-class="opacity-0" enter-active-class="transition duration-200" leave-to-class=" opacity-0"
      leave-active-class="transition duration-200">
      <div v-show="stateLogin" class="fixed z-[100] inset-0 bg-slate-900/60 transition-opacity"></div>
    </Transition>
    <!-- Модальное окно авторизации -->
    <Transition enter-from-class="opacity-0 scale-95" enter-active-class="transition duration-200"
      leave-to-class="opacity-0 scale-95" leave-active-class="transition duration-200">
      <div v-show="stateLogin" class="fixed inset-0 flex items-center justify-center z-[105]">
        <div class="w-full sm:rounded-xl bg-white max-w-md z-[106] lg:-mt-60 relative">
          <!--  LOGO  -->
          <div class="flex flex-col justify-center items-center text-sky-900 p-8">
            <div class="flex justify-center items-center">
              <span>
                <LogoIcon />
              </span>

              <h2 class="ml-2 sm:ml-4 text-2xl font-bold">AQUADECOR</h2>
            </div>

            <h5 class="pt-4 sm:pt-8 text-slate-400 text-sm">
              Вхід до особистого кабінету
            </h5>
          </div>
          <div class="px-4 pb-4 sm:pb-6 sm:px-6">
            <form @submit.prevent="submitForm" class="space-y-6">
              <div class="relative w-full flex items-center mt-4">
                <input
                  class="block peer w-full appearance-none rounded-md border border-slate-300 hover:border-slate-400 transition duration-150 bg-white px-3 py-2 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                  type="text" v-model="username" placeholder="Логін" />
                <span
                  class="absolute right-0 w-10 flex items-center justify-center text-slate-400 peer-focus:text-blue-600 transition duration-150">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                  </svg>
                </span>
              </div>
              <div class="relative w-full flex items-center">
                <input
                  class="block peer w-full appearance-none rounded-md border border-slate-300 hover:border-slate-400 transition duration-150 bg-white px-3 py-2 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                  type="password" v-model="password" placeholder="Пароль" />
                <span
                  class="absolute right-0 w-10 flex items-center justify-center text-slate-400 peer-focus:text-blue-600 transition duration-150">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                  </svg>
                </span>
              </div>
              <div class="flex items-center w-full py-4">
                <span @click="toggle" class="btn-secondary w-full text-center mr-4 sm:mr-8 cursor-pointer">Закрити</span>
                <button type="submit" class="btn-primary w-full text-center flex items-center space-x-4">
                  <span>Вхід</span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div v-show="errorMessage"
            class="absolute bottom-0 left-0 flex w-full justify-center px-4 py-0.5 sm:py-1 text-center bg-red-600 text-white sm:rounded-b-xl overflow-hidden">
            <span class="text-sm">{{ errorMessage }}</span>
          </div>
        </div>
      </div>
    </Transition>

    <Transition enter-from-class="opacity-0" enter-active-class="transition duration-200" leave-to-class=" opacity-0"
      leave-active-class="transition duration-200">
      <div v-show="stateLogOut" class="fixed z-[105] inset-0 bg-slate-900/60 transition-opacity"></div>
    </Transition>
    <!-- Модальное выхода из акаунта -->
    <Transition enter-from-class="opacity-0 scale-95" enter-active-class="transition duration-200"
      leave-to-class="opacity-0 scale-95" leave-active-class="transition duration-200">
      <div v-show="stateLogOut" class="fixed inset-0 flex items-center justify-center z-[106]">
        <div class="w-full sm:rounded-xl bg-white max-w-md z-[106] lg:-mt-60 relative">
          <!--  LOGO  -->
          <div class="flex flex-col justify-center items-center text-sky-900 p-8">
            <div class="flex justify-center items-center">
              <span>
                <LogoIcon />
              </span>

              <h2 class="ml-2 sm:ml-4 text-2xl font-bold">AQUADECOR</h2>
            </div>

            <h5 class="pt-4 sm:pt-8 text-slate-600 text-sm">
              Бажаєти вийти із акаунта?
            </h5>
          </div>
          <div class="px-4 pb-4 sm:pb-6 sm:px-6 flex w-full items-center justify-between">
            <button @click="updateStateLogOut" class="btn-secondary">
              Скасувати
            </button>
            <button @click="logOut" class="btn-primary">Підтвердити</button>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import LoginIcon from "@/components/icons/LoginIcon";
import LoginOut from "@/components/icons/LoginOut";
import LogoIcon from "@/components/icons/LogoIcon";
import ThemeSwitcher from "@/components/ThemeSwitcher.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "ButtonLoginHeader",

  components: {
    LoginIcon,
    LogoIcon,
    LoginOut,
    ThemeSwitcher,
  },

  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      token: null,
      stateUSD: "",
      stateERO: "",
    };
  },

  computed: {
    ...mapState([
      "stateLogin",
      "stateLogOut",
      "autorized",
      "stateAcountModal",
      "profileAuthAcount",
      "profileData",
      "exchangeData",
      "API_URL",
    ]),
  },

  methods: {
    ...mapMutations([
      "updateStateLogin",
      "updateStateLogOut",
      "getAutorizerdtData",
      "setAutorizerdtData",
      "toggleAcount",
      "setAuthUser",
      "setProfileData",
    ]),
    // Api авторизация
    submitForm() {
      const url1 = `${this.API_URL}${"auth/token/login/"}`;
      fetch(url1, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: this.username,
          password: this.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.auth_token) {
            this.setAutorizerdtData("true");
            localStorage.setItem("accessToken", data.auth_token);
            this.token = data.auth_token;
            this.toggle();
            this.getUserData();
            this.toggleAcount("true");
            //this.$router.push("/");
          } else {
            this.errorMessage = "Невірний логін або пароль!";
            // console.log("Error get token");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // Api LogOut
    logOut() {
      const url2 = `${this.API_URL}${"auth/token/logout/"}`;
      fetch(url2, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response);
          if (response.status === 204) {
            // удаление токенов из локального хранилища
            this.setAutorizerdtData("false");
            localStorage.removeItem("accessToken");
            this.toggleAcount("false");
            this.updateStateLogOut("false");

            // перенаправление на страницу логина
            this.$router.push("/");
          } else {
            // обработка ошибки
            console.log("локал не очищен");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // Api данные о пользователе
    getUserData() {
      const url3 = `${this.API_URL}${"api/v1/auth/users/me/"}`;
      fetch(url3, {
        method: "GET",
        headers: {
          Authorization: `Token ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.setAuthUser(data);
            this.getProfileData();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // Api данные профиля
    async getProfileData() {
      const idUser = this.profileAuthAcount.id;
      const API_URL = `${this.API_URL}${"api/v1/get-customer/"}`;

      const url = `${API_URL}${idUser}/`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Token ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await response.json();

      this.setProfileData(data);
    },
    toggle() {
      this.updateStateLogin(!this.stateLogin);
      this.username = "";
      this.password = "";
      this.errorMessage = "";
    },
  },
  mounted() {
    this.getAutorizerdtData(); // Проверяем состояние авторизации в локале
    if (this.autorized) {
      this.getUserData(); // Берем данные о пользователе через токен
    }

    // this.getProfileData(); // Api данные профиля
    // console.log("autorized - " + this.autorized);
    // console.log(this.exchangeData[0]);
  },
};
</script>
