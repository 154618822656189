<template>
  <div class="relative flex items-center lg:hidden">
    <div class="flex items-center" v-show="!mobileMenu">
      <Transition enter-from-class="-translate-x-full opacity-0" enter-active-class="transition duration-300"
        leave-active-class="transition duration-300" leave-to-class="-translate-x-full opacity-0">
        <button aria-label="Мобільне меню" v-show="!mobileMenu" @click="mobileMenu = !mobileMenu"
          class="cursor-pointer text-slate-600">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-7 h-7">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
          </svg>
        </button>
      </Transition>
    </div>
    <div style="display: none" v-show="mobileMenu" class="flex items-center">
      <Transition enter-from-class="-translate-x-full opacity-0" enter-active-class="transition duration-300"
        leave-active-class="transition duration-300" leave-to-class="-translate-x-full opacity-0 ">
        <button aria-label="Закрти мобільне меню" v-show="mobileMenu" @click="mobileMenu = !mobileMenu"
          class="cursor-pointer text-slate-600">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-7 h-7">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </Transition>
    </div>

    <Transition enter-from-class="opacity-0" enter-active-class="transition duration-300" leave-to-class="opacity-0"
      leave-active-class="transition duration-300">
      <div v-if="mobileMenu" @click="mobileMenu = !mobileMenu"
        class="hidden sm:block fixed left-0 top-[68px] z-[101] h-full w-full bg-slate-900/60 transition-opacity"></div>
    </Transition>

    <Transition enter-from-class="-translate-x-full opacity-0" enter-active-class="transition duration-300"
      leave-to-class="-translate-x-full opacity-0 " leave-active-class="transition duration-300">
      <div style="display: none" v-show="mobileMenu" class="fixed left-0 top-[68px] z-[101] h-full w-full">
        <div class="h-full w-full sm:w-96 transform-gpu bg-white transition-transform duration-200 p-4 sm:p-6 lg:p-8">
          <nav class="mt-4">
            <div class="flex flex-col space-y-3 text-slate-900 text-base">
              <router-link class="flex items-center p-3 bg-slate-100 rounded-lg" to="/"
                @click="mobileMenu = !mobileMenu"><span>Головна</span></router-link>
              <router-link class="flex items-center p-3 bg-slate-100 rounded-lg" to="/services"
                @click="mobileMenu = !mobileMenu"><span>Замовити послугу</span></router-link>
              <router-link class="flex items-center p-3 bg-slate-100 rounded-lg" to="/shop"
                @click="mobileMenu = !mobileMenu"><span>Замовити товар</span></router-link>
              <router-link class="flex items-center p-3 bg-slate-100 rounded-lg" to="/documentations"
                @click="mobileMenu = !mobileMenu"><span>Документації та посібники</span></router-link>
              <router-link class="flex items-center p-3 bg-slate-100 rounded-lg" to="/contact"
                @click="mobileMenu = !mobileMenu"><span>Про нас</span></router-link>
            </div>
          </nav>
          <div class="mt-8 space-y-3 flex flex-col text-slate-900 text-base">
            <a href="tel:+380508551348" class="flex items-center justify-between p-3 bg-slate-50 rounded-lg">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6 text-slate-600">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                </svg>
              </span>
              <span>+38 050 855 13 48</span>
            </a>
            <a href="mailto:poliv@aquadecor.ua" class="flex items-center justify-between p-3 bg-slate-50 rounded-lg">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-7 h-7 text-gray-500 group-hover:text-blue-600">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
              </span>
              <span>info@aquadecor.ua</span>
            </a>
          </div>
          <div class="mt-8 space-y-3 flex flex-col text-base">
            <router-link to="/contact" @click="mobileMenu = !mobileMenu"
              class="flex items-center justify-center p-3 bg-blue-600 text-white rounded-full">
              <span>Стати партнером</span>
            </router-link>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "MobileMenu",
  components: {},
  computed: {},
  data() {
    return {
      mobileMenu: false,
    };
  },
  mounted() { },
  methods: {},
};
</script>
