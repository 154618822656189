<template>
  <div>
    <section>
      <div class="pt-20">
        <div class="pt-20">
          <div class="container sm:px-6 lg:px-8 text-center">
            <h1 class="mx-auto max-w-4xl text-4xl sm:text-5xl md:text-6xl font-medium tracking-tight lg:text-7xl ">
              {{ data.header_first }}
              <span class="relative whitespace-nowrap text-blue-600">
                <Transition enter-from-class=" opacity-0" enter-active-class="transition duration-500 delay-[2000ms]">
                  <div v-if="animMain" class="absolute top-2/3 left-0 h-[0.58em] w-full">
                    <svg aria-hidden="true" viewBox="0 0 281 40" class="h-full w-full fill-blue-300/70"
                      preserveAspectRatio="none">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M240.172 22.994c-8.007 1.246-15.477 2.23-31.26 4.114-18.506 2.21-26.323 2.977-34.487 3.386-2.971.149-3.727.324-6.566 1.523-15.124 6.388-43.775 9.404-69.425 7.31-26.207-2.14-50.986-7.103-78-15.624C10.912 20.7.988 16.143.734 14.657c-.066-.381.043-.344 1.324.456 10.423 6.506 49.649 16.322 77.8 19.468 23.708 2.65 38.249 2.95 55.821 1.156 9.407-.962 24.451-3.773 25.101-4.692.074-.104.053-.155-.058-.135-1.062.195-13.863-.271-18.848-.687-16.681-1.389-28.722-4.345-38.142-9.364-15.294-8.15-7.298-19.232 14.802-20.514 16.095-.934 32.793 1.517 47.423 6.96 13.524 5.033 17.942 12.326 11.463 18.922l-.859.874.697-.006c2.681-.026 15.304-1.302 29.208-2.953 25.845-3.07 35.659-4.519 54.027-7.978 9.863-1.858 11.021-2.048 13.055-2.145a61.901 61.901 0 0 0 4.506-.417c1.891-.259 2.151-.267 1.543-.047-.402.145-2.33.913-4.285 1.707-4.635 1.882-5.202 2.07-8.736 2.903-3.414.805-19.773 3.797-26.404 4.829Zm40.321-9.93c.1-.066.231-.085.29-.041.059.043-.024.096-.183.119-.177.024-.219-.007-.107-.079ZM172.299 26.22c9.364-6.058 5.161-12.039-12.304-17.51-11.656-3.653-23.145-5.47-35.243-5.576-22.552-.198-33.577 7.462-21.321 14.814 12.012 7.205 32.994 10.557 61.531 9.831 4.563-.116 5.372-.288 7.337-1.559Z">
                      </path>
                    </svg>
                  </div>
                </Transition>

                <Transition enter-from-class="text-slate-900" enter-to-class=" text-blue-600"
                  enter-active-class="transition duration-[1000ms]">
                  <span v-if="animMain" class="relative">
                    {{ data.header_second }}</span>
                </Transition>
              </span>
            </h1>
            <p class="mx-auto mt-8 xl:mt-6 max-w-2xl md:text-lg tracking-tight text-[#142251]/80">
              {{ data.title }}
            </p>

            <div class="mt-8 xl:mt-6 flex justify-center gap-x-6">
              <router-link class="btn-primary" to="services">
                <span class="hidden sm:inline">Замовити послугу</span>
                <span class="sm:hidden px-4">Послуги</span>
              </router-link>
              <router-link class="btn-secondary" to="shop">
                <span class="hidden sm:inline">Замовити обладнання</span>
                <span class="sm:hidden px-4">Товари</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ANIMATIONS -->
    <section>
      <div class="mt-28 w-full">
        <div class="relative">
          <!--  PEOPLE  -->
          <div class="container mx-auto sm:px-6 lg:px-8 z-40 pb-1 lg:pb-2 xl:pb-4">
            <Transition enter-from-class="-translate-x-[150%] opacity-0"
              enter-active-class="transition duration-[1000ms]">
              <div v-show="animMain" class="flex w-full justify-center">
                <img class=" h-16 w-[358px] sm:h-[129px] sm:w-[720px]  xl:h-[219px] xl:w-[1216px]"
                  src="@/assets/images/people_and_grass/all_people_no.svg" alt="Системи зрошення" />
              </div>

            </Transition>
          </div>
          <!--  WATER  -->
          <div
            class="absolute bottom-1 sm:bottom-2 lg:bottom-3 xl:bottom-5 left-[calc(85%-12rem)] h-4 sm:h-8 sm:left-[calc(61%-12rem)] xl:left-[calc(50%-12rem)] xl:h-14 -z-40">
            <Transition enter-from-class="opacity-0" enter-active-class="transition duration-[1000ms] delay-[1000ms]">
              <img v-show="animMain" class="h-4 w-[216px] sm:w-[433px] xl:w-[758px] sm:h-8 xl:h-14"
                src="@/assets/images/people_and_grass/sprey_water.svg" alt="Системи зрошення" />
            </Transition>
          </div>
          <!--  GRASS GREY  -->
          <div class="absolute bottom-0 left-0 -z-50 flex flex-col w-full">
            <Transition leave-to-class="opacity-0" leave-active-class="transition duration-500 delay-[2000ms]">
              <div v-show="animMain">
                <div class="flex justify-center w-full overflow-hidden">
                  <img class="h-full w-full" src="@/assets/images/people_and_grass/grass_grey_400-mini.webp"
                    alt="Системи зрошення" />
                </div>

                <div class="w-full h-2 md:h-3 lg:h-5 xl:h-8 -mt-[1px] bg-gray-400"></div>
              </div>
            </Transition>
          </div>
          <!--  GRASS GREEN  -->
          <div class="absolute bottom-0 left-0 -z-50 flex flex-col w-full">
            <Transition enter-from-class="opacity-0" enter-active-class="transition duration-500 delay-[2000ms]">
              <div v-show="animMain">
                <div class="flex justify-center w-full overflow-hidden">
                  <img class=" h-full w-full " src="@/assets/images/people_and_grass/grass_green_mini.webp"
                    alt="Системи зрошення" />
                </div>

                <div class="w-full h-2 md:h-3 lg:h-5 xl:h-8 -mt-[1px] bg-[#08AD1E]"></div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HeroBlock",
  components: {},
  data() {
    return {
      animMain: false, // Анимация главной страницы начальное значение false
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  mounted() {
    this.animMain = true; // Запускаем анимацию главных блоков при загрузке страницы
  },
  methods: {},
};
</script>
