<template>
  <footer v-if="!autorized" class="border-t border-slate-200 dark:border-slate-600 bg-slate-50 dark:bg-slate-800">
    <div class="container sm:px-6 lg:px-8">
      <div class="py-16">
        <div class="flex justify-start items-center text-main">
          <router-link class="inline-flex items-center" to="/">
            <div class="p-0.5 rounded-full dark:bg-slate-200">
              <LogoIcon />
            </div>
            <h2 class="ml-4 text-2xl font-bold dark:text-slate-200">
              AQUADECOR
            </h2>
          </router-link>
        </div>
        <NavFooter />
      </div>
      <div
        class="flex flex-col items-center space-y-2 sm:space-y-0 border-t border-slate-400/10 py-10 sm:flex-row sm:justify-between">
        <div class="text-sm text-[#142251]/80">
          © Copyright 2023. Усі права захищено.
        </div>
        <div class="text-sm text-[#142251]/80 flex items-center space-x-4">
          <div>Зроблено в Україні</div>
          <div class="h-6">
            <img class="h-full" src="@/assets/images/Flag_of_Ukraine.webp" alt="Flag of Ukraine">
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import NavFooter from "@/components/footer/NavFooter";
import LogoIcon from "@/components/icons/LogoIcon.vue";
import { mapState } from "vuex";
export default {
  name: "FooterMain",
  components: {
    NavFooter,
    LogoIcon,
  },
  computed: {
    ...mapState(["autorized"]),
  },
};
</script>
