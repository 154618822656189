<template>
  <HeaderMain />
  <div class="min-h-screen">
    <div v-if="!loader">
      <router-view />
    </div>
  </div>

  <FooterMain />
</template>

<script>
import HeaderMain from "@/components/header/HeaderMain";
import FooterMain from "@/components/footer/FooterMain";
import { mapState, mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    HeaderMain,
    FooterMain,
  },
  data() {
    return {
      categoryData: [],
      productsData: [],
      loader: true,
    };
  },
  async created() {
    const url5 = `${this.API_URL}${"api/v1/blocks_home_page/"}`;
    const url1 = `${this.API_URL}${"api/v1/get-category-products/"}`;
    const url2 = `${this.API_URL}${"api/v1/get-products/"}`;
    const url3 = `${this.API_URL}${"api/v1/blocks_contact_page/"}`;
    const url4 = `${this.API_URL}${"api/v1/get-services-all-blocks/"}`;

    const url6 = `${this.API_URL}${"api/v1/faq_on_home_page/"}`;
    //const url7 = `${this.API_URL}${"api/v1/category_faq/"}`;
    const url8 = `${this.API_URL}${"api/v1/discount/"}`;
    const url9 = `${this.API_URL}${"api/v1/exchange/"}`;
    // const url1 = "https://aqua-dev.badvps.com/api/v1/get-category-products/";
    // const url2 = "https://aqua-dev.badvps.com/api/v1/get-products/";
    // const url3 = "https://aqua-dev.badvps.com/api/v1/blocks_contact_page/";
    // const url4 = "https://aqua-dev.badvps.com/api/v1/get-services-all-blocks/";
    // const url5 = "https://aqua-dev.badvps.com/api/v1/blocks_home_page/";
    // const url6 = "https://aqua-dev.badvps.com/api/v1/faq_on_home_page/";
    // const url7 = "https://aqua-dev.badvps.com/api/v1/category_faq/";
    // const url8 = "https://aqua-dev.badvps.com/api/v1/discount/"; // Discount
    // const url9 = "https://aqua-dev.badvps.com/api/v1/exchange/"; // Курси валют

    const response2 = await fetch(url2);
    const data2 = await response2.json();
    this.productsData = data2;

    const response1 = await fetch(url1);
    const data1 = await response1.json();
    this.savedCategoryData(data1);
    const response8 = await fetch(url8);
    const response9 = await fetch(url9);
    const data8 = await response8.json();
    const data9 = await response9.json();
    this.saveExchangeData(data9);
    this.saveDiscountData(data8);

    this.getCartData();
    this.calcTotalCartPrice();
    this.addPoleAndReloadAllProductsData();
    this.saveProductsData(data2);

    const response5 = await fetch(url5);
    const data5 = await response5.json();
    this.saveHomePageBlocks(data5);
    this.loader = false;

    const response4 = await fetch(url4);
    const data4 = await response4.json();
    this.saveAllServicesDataAndBlocks(data4);

    const response3 = await fetch(url3);

    const response6 = await fetch(url6);
    //const response7 = await fetch(url7);

    const data3 = await response3.json();

    const data6 = await response6.json();
    //const data7 = await response7.json();

    //this.saveDocumentation(data7);

    this.saveHomePageFaqDate(data6);

    this.saveContactPageData(data3);

    this.categoryData = data1;
  },
  computed: {
    ...mapState([
      "cart",
      "autorized",
      "discountData",
      "exchangeData",
      "API_URL",
    ]),
  },
  mounted() {},
  methods: {
    ...mapMutations([
      "saveProductsData",
      "savedCategoryData",
      "getCartData",
      "calcTotalCartPrice",
      "saveContactPageData",
      "saveAllServicesDataAndBlocks",
      "saveHomePageBlocks",
      "saveHomePageFaqDate",
      "saveDocumentation",
      "saveDiscountData",
      "saveExchangeData",
    ]),
    // Функия добавления необходимых полей в масив продуктов, сравнение с корзиной и перезапись данных
    addPoleAndReloadAllProductsData() {
      // Добавляем нужные данные в продукты
      this.productsData.forEach((item) => {
        // добавляем значения в масив
        item.quantity = 1; // здесь формируем начальное количество в поле ввода
        item.cart_quantity = 0; // здесь формируем начальное количество в карточке товара
        item.in_cart = false; // здесь устанавливаем есть ли товар в корзине (по умолчанию нет)
        item.anim_update = false; // здесь устанавливаем значение анимации при апдейте колличества товаров в корзине
        // Hunter
        if (item.manufacturer === 1) {
          item.price_ua = (
            item.price_ue *
            this.discountData[0].discount_percent *
            this.exchangeData[0].exchange
          ).toFixed(0); // здесь устанавливаем начальную общую сумму
        }
        // Irritec
        if (item.manufacturer === 2) {
          item.price_ua = (
            item.price_ue *
            this.discountData[1].discount_percent *
            this.exchangeData[1].exchange
          ).toFixed(0); // здесь устанавливаем начальную общую сумму
        }
        // Gidrants
        if (item.manufacturer === 5) {
          item.price_ua = (
            item.price_ue *
            this.discountData[4].discount_percent *
            this.exchangeData[1].exchange
          ).toFixed(0); // здесь устанавливаем начальную общую сумму
        }
        // Dripline
        if (item.manufacturer === 6) {
          item.price_ua = (
            item.price_ue *
            this.discountData[5].discount_percent *
            this.exchangeData[1].exchange
          ).toFixed(0); // здесь устанавливаем начальную общую сумму
        }
        // Fittings
        if (item.manufacturer === 3) {
          item.price_ua = (
            item.price_ue *
            this.discountData[2].discount_percent *
            this.exchangeData[1].exchange
          ).toFixed(0); // здесь устанавливаем начальную общую сумму
        }
        // Box
        if (item.manufacturer === 4) {
          item.price_ua = (
            item.price_ue *
            this.discountData[3].discount_percent *
            this.exchangeData[1].exchange
          ).toFixed(0); // здесь устанавливаем начальную общую сумму
        }

        item.total_price = item.price_ua; // здесь устанавливаем начальную общую сумму
      });

      // Сравниваем товары в корзине и список товаров, если товар есть в корзине то устанавливаем колличество равное колличеству в корзине
      if (this.cart.length > 0) {
        this.productsData.forEach((item) => {
          this.cart.forEach((i) => {
            if (i.id === item.id) {
              item.quantity = i.cart_quantity;
              item.cart_quantity = i.cart_quantity;
              item.in_cart = true;
              item.total_price = i.total_price;
            }
          });
        });
      }

      // console.log("OK - Товари загружено"); // Выводим сообщение в лог
    },
  },
};
</script>
