<template>
  <nav class="lg:flex justify-center gap-x-12 font-medium text-base hidden dark:text-slate-200">
    <router-link @mouseover="setShowDropdownServices(true)" @mouseleave="setShowDropdownServices(false)"
      @click="setIsPageActive('services')" :class="[isPageActive === 'services' ? 'text-blue-600 ' : '']"
      class="hover:text-blue-600 transition-all duration-150 relative" to="/services">Послуги
      <DropDownServiceHeaderMenu v-show="showDropdownServices" />
    </router-link>


    <router-link @mouseover="setShowDropdownProducts(true)" @mouseleave="setShowDropdownProducts(false)"
      :class="[isPageActive === 'products' ? 'text-blue-600 ' : '']"
      class="hover:text-blue-600 transition-all duration-150 relative" to="/shop" @click="setIsPageActive('')">Товари

      <DropDownProductHeaderMenu v-show="showDropdownProducts && isPageActive !== 'products'" />


    </router-link>

    <router-link :class="[isPageActive === 'doc' ? 'text-blue-600 dark:text-sky-400' : '']"
      class="hover:text-blue-600 transition-all duration-150" to="/documentations"
      @click="setIsPageActive('')">Документація</router-link>

    <router-link class="hover:text-blue-600 transition-all duration-150" to="/contact"
      @click="setIsPageActive('')">Контакти</router-link>
  </nav>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import DropDownServiceHeaderMenu from "@/components/header/DropDownSeviceHeaderMenu";
import DropDownProductHeaderMenu from "@/components/header/DropDownProductHeaderMenu.vue";
export default {
  name: "NavHeader",
  components: {
    DropDownServiceHeaderMenu,
    DropDownProductHeaderMenu,

  },
  data() {
    return {
      // showDropdownServices: false,
      // showDropdownProducts: false,
    };
  },
  methods: {
    ...mapMutations(["setIsPageActive", "setShowDropdownServices", "setShowDropdownProducts"]),
  },
  computed: {
    ...mapState(["isPageActive", "showDropdownServices", "showDropdownProducts"]),
  },
};
</script>

<style scoped>
.router-link-active {
  color: #2563eb;
}
</style>
