<template>
  <div class="relative items-center flex">
    <button
    aria-label="Телефон"
      @click="toggle"
      :class="[
        statePhone ? 'text-blue-600' : 'text-primary dark:text-slate-200',
      ]"
      class="cursor-pointer hover:text-blue-600 dark:hover:text-blue-600 transition-all duration-150"
    >
      <PhoneIcon />
    </button>
    <Transition
      enter-from-class=" opacity-0"
      enter-active-class="transition duration-300"
      leave-to-class="opacity-0"
      leave-active-class="transition duration-300"
    >
      <div
        v-show="statePhone"
        @click="toggle"
        class="fixed z-[100] inset-0 bg-slate-900/60 transition-opacity xl:hidden"
      ></div>
    </Transition>
    <Transition
      enter-from-class="translate-y-full opacity-0"
      enter-active-class="transition duration-300"
      leave-to-class="translate-y-full opacity-0"
      leave-active-class="transition duration-300"
    >
      <div
        style="display: none"
        v-show="statePhone"
        class="fixed text-sm left-0 bottom-0 w-full py-4 text-slate-900 border bg-white z-[102] xl:hidden"
      >
        <ul class="flex flex-col space-y-2">
          <li>
            <a
              href="tel:+380673182780"
              class="flex items-center justify-between group transition-all duration-150 px-4 py-2 hover:bg-slate-100"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.3"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>
              </span>
              +38 067 318 27 80
            </a>
          </li>
          <li>
            <a
              href="mailto:poliv@aquadecor.ua"
              class="flex items-center justify-between group transition-all duration-150 px-4 py-2 hover:bg-slate-100"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.3"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </span>
              info@aquadecor.ua
            </a>
          </li>
        </ul>
      </div>
    </Transition>
    <Transition
      enter-from-class="-translate-x-full opacity-0"
      enter-active-class="transition duration-300"
      leave-to-class="-translate-x-full opacity-0"
      leave-active-class="transition duration-300"
    >
      <div
        style="display: none"
        v-show="statePhone"
        class="absolute text-sm right-7 -top-5.5 py-4 w-32 z-[102] hidden xl:flex"
      >
        <a
          href="tel:+380673182780"
          class="flex items-center justify-between group transition-all duration-150 hover:text-blue-600 dark:hover:text-blue-600 dark:text-slate-200 font-medium"
        >
          +38 067 318 27 80
        </a>
      </div>
    </Transition>
  </div>
</template>

<script>
import PhoneIcon from "@/components/icons/PhoneIcon";
import { mapState, mapMutations } from "vuex";

export default {
  name: "ButtonPhoneHeader",
  components: {
    PhoneIcon,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["statePhone"]),
  },
  props: {},
  mounted() {},
  methods: {
    ...mapMutations(["updateStatePhone"]),
    toggle() {
      this.updateStatePhone(!this.statePhone);
    },
  },
};
</script>
