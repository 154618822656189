import { createApp } from "vue";
import App from "./App.vue";
import "./assets/css/main.css";
import router from "./router";
import store from "./store";

// Проверяем авторизацию и предоставляем доступ к страницам
function isAuthenticated() {
  return localStorage.getItem("accessToken") !== null;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

createApp(App).use(store).use(router).mount("#app");
