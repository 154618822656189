<template>
  <section>
    <form @submit.prevent="createdLead">
      <div class="md:grid md:grid-cols-2 space-y-8 md:gap-8 md:space-y-0">
        <div class="w-full">
          <label class="mb-3 block text-slate-700">
            <span class="flex items-center relative">
              <input v-model="name_customer"
                class="block peer w-full appearance-none rounded-md border border-[#142251]/20 hover:border-[#142251]/80 transition duration-150 bg-white py-2 pl-9 pr-3.5 placeholder:text-[#142251]/50 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                type="text" placeholder="Ваше ім'я" />
              <span
                class="absolute w-10 flex items-center justify-center text-[#142251]/80 peer-focus:text-blue-600 transition duration-150">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
              </span>
            </span>
          </label>
        </div>
        <div class="w-full">
          <label class="mb-3 block text-slate-700">
            <span class="flex items-center relative">
              <input v-model="phone_number" v-bind:maxlength="9" v-on:keypress="validatePhoneNumber"
                class="block peer w-full appearance-none rounded-md border border-[#142251]/20 hover:border-[#142251]/80 transition duration-150 bg-white py-2 pl-20 pr-3.5 placeholder:text-[#142251]/50 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                type="tel" placeholder="Ваш номер телефону" />
              <span
                class="absolute w-10 flex items-center justify-center text-[#142251]/80 peer-focus:text-blue-600 transition duration-150">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                  </svg>
                </span>
              </span>
              <span
                class="absolute w-10 flex items-center justify-center text-[#142251]/80 peer-focus:text-slate-900 transition duration-150 left-9">+380</span>
            </span>
          </label>
        </div>
      </div>

      <div class="mt-8">
        <label class="mb-3 block text-slate-700">
          <span class="flex">
            <textarea id="message" v-model="message" rows="3"
              class="block w-full px-3.5 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500 transition duration-150 rounded-md border bg-white border-[#142251]/20 hover:border-[#142251]/80 placeholder:text-[#142251]/50"
              placeholder="Ваше питання"></textarea>
          </span>
        </label>
      </div>

      <div class="mt-8 text-right flex justify-center sm:justify-end relative">
        <button type="submit" class="btn-primary w-48 justify-center">
          Надіслати
        </button>
        <Transition enter-from-class="opacity-0 scale-95" enter-active-class="transition duration-200"
          leave-to-class="opacity-0 scale-95" leave-active-class="transition duration-200">
          <div v-show="alert"
            class="absolute top-14 sm:top-0 sm:left-0 text-center px-4 py-2 rounded-xl bg-green-600 text-white">
            Повідомлення надіслано
          </div>
        </Transition>
        <Transition enter-from-class="opacity-0 scale-95" enter-active-class="transition duration-200"
          leave-to-class="opacity-0 scale-95" leave-active-class="transition duration-200">
          <div v-show="error" class="absolute top-0 left-0 text-center px-6 py-2 rounded-md bg-red-600 text-white">
            {{ errorMesage }}
          </div>
        </Transition>
      </div>
    </form>
  </section>
</template>
<script>
// @ is an alias to /src
import { mapState } from "vuex";
export default {
  name: "CallBackForm",
  components: {},
  data() {
    return {
      alert: false,
      error: false,
      name_customer: "",
      phone_number: "",
      message: "",
      errorMesage: "",
      // customerInBase: null,
      // allCustomersData: [],
      // orderID: null,
      // customerID: null,
    };
  },
  created() { },
  computed: {
    ...mapState(["API_URL"]),
  },
  mounted() { },
  methods: {
    validatePhoneNumber(event) {
      const keyCode = event.keyCode;
      const excludedKeys = [8, 37, 39, 46];
      if (
        (event.shiftKey === true && keyCode === 32) ||
        (keyCode === 32 && this.phone_number === "") ||
        excludedKeys.includes(keyCode)
      ) {
        event.preventDefault();
      } else {
        if (this.phone_number.length > 9) {
          event.preventDefault();
        } else {
          const regex = /^[0-9]+$/;
          const inputChar = String.fromCharCode(keyCode);
          if (!regex.test(inputChar)) {
            event.preventDefault();
          }
        }
      }
    },
    // Одним запросом
    createdLead() {
      if (this.name_customer != "") {
        if (this.phone_number.length < 9) {
          this.errorMesage = "Будь ласка перевірьте номер телефону";
          this.error = true;
          setTimeout(() => {
            this.error = false; // ваша функция здесь
          }, 3000);
        } else {
          const url = `${this.API_URL}${"api/v1/create-lead/"}`;
          const data = {
            // Данные нового заказа
            name: "Звернення з форми CallBack", // Name Odrer
            type: 3, // Type Order
            status: 3, // Status Order
            from_order: 2, // From Order
            last_name: "Заповнити",
            status_customer: 1,
            comment_customer: "Контакт створено з форми CallBack",
            name_item_order: "Элемент звернення CallBack",
            number_phone: this.phone_number,
            first_name: this.name_customer,
            message: this.message,
          };
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((data) => {
              this.alert = true;
              setTimeout(() => {
                this.alert = false; // ваша функция здесь
              }, 3000);
              this.name_customer = "";
              this.phone_number = "";
              this.message = "";
              console.log("Успешно создан новый элемент:", data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.errorMesage = "Будь ласка перевірьте ім'я";
        this.error = true;
        setTimeout(() => {
          this.error = false; // ваша функция здесь
        }, 3000);
      }
    },
    // Тремя запросами
    // async createNewOrderLead() {
    //   const url1 = `${this.API_URL}${"api/v1/customers/"}`;
    //   const response1 = await fetch(url1);
    //   const data1 = await response1.json();
    //   this.allCustomersData = data1;
    //   console.log("data customers " + this.allCustomersData);
    //   const customer = this.allCustomersData.find(
    //     (cust) => cust.phone_number === this.phone_number
    //   );
    //   console.log("data customer " + customer);
    //   if (customer) {
    //     console.log(
    //       `Customer found! Name: ${customer.name}, Phone: ${customer.id}`
    //     );
    //     // **********************
    //     const url2 = `${this.API_URL}${"api/v1/new-orders/"}`;
    //     fetch(url2, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         name: "Звернення з форми CallBack",
    //         type: 3,
    //         status: 3,
    //         from_order: 2,
    //         customer: customer.id,
    //       }),
    //     })
    //       .then((response) => {
    //         if (!response.ok) {
    //           throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    //         return response.json();
    //       })
    //       .then((data) => {
    //         this.orderID = data.id;
    //         console.log("orderID - " + this.orderID);
    //         console.log("Успешно создан новый запрос:", data);
    //         // **********************
    //         const url3 = `${this.API_URL}${"api/v1/new-callback/"}`;
    //         fetch(url3, {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //           body: JSON.stringify({
    //             name: "Элемент звернення CallBack",
    //             number_phone: this.phone_number,
    //             first_name: this.name_customer,
    //             message: this.message,
    //             order: this.orderID,
    //           }),
    //         })
    //           .then((response) => {
    //             if (!response.ok) {
    //               throw new Error(`HTTP error! status: ${response.status}`);
    //             }
    //             return response.json();
    //           })
    //           .then((data) => {
    //             this.alert = true;
    //             setTimeout(() => {
    //               this.alert = false; // ваша функция здесь
    //             }, 3000);
    //             this.name_customer = "";
    //             this.phone_number = "";
    //             this.message = "";
    //             console.log("Успешно создан новый элемент:", data);
    //           })
    //           .catch((error) => {
    //             console.error("Ошибка при создании элемента:", error);
    //           });
    //         //******************* */
    //       })
    //       .catch((error) => {
    //         console.error("Ошибка при создании запроса:", error);
    //       });
    //     //******************* */
    //   } else {
    //     console.log(`Customer not found for phone number ${this.phone_number}`);
    //     // ****************************************************************************************************************************
    //     const url3 = `${this.API_URL}${"api/v1/new-customers/"}`;
    //     fetch(url3, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         name: this.name_customer,
    //         phone_number: this.phone_number,
    //         status: 1,
    //         last_name: "Заповнити",
    //         e_mail: "",
    //         address: "",
    //         comment: "Контакт створено з форми CallBack",
    //       }),
    //     })
    //       .then((response) => {
    //         if (!response.ok) {
    //           throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    //         return response.json();
    //       })
    //       .then((data) => {
    //         this.customerID = data.id;
    //         console.log("customerID - " + this.customerID);
    //         // **********************
    //         const url4 = `${this.API_URL}${"api/v1/new-orders/"}`;
    //         fetch(url4, {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //           body: JSON.stringify({
    //             name: "Звернення з форми CallBack",
    //             type: 3,
    //             status: 3,
    //             from_order: 2,
    //             customer: this.customerID,
    //           }),
    //         })
    //           .then((response) => {
    //             if (!response.ok) {
    //               throw new Error(`HTTP error! status: ${response.status}`);
    //             }
    //             return response.json();
    //           })
    //           .then((data) => {
    //             this.orderID = data.id;
    //             console.log("orderID - " + this.orderID);
    //             console.log("Успешно создан новый запрос:", data);
    //             // **********************
    //             const url5 = `${this.API_URL}${"api/v1/new-orders/"}`;
    //             fetch(url5, {
    //               method: "POST",
    //               headers: {
    //                 "Content-Type": "application/json",
    //               },
    //               body: JSON.stringify({
    //                 name: "Элемент звернення CallBack",
    //                 number_phone: this.phone_number,
    //                 first_name: this.name_customer,
    //                 message: this.message,
    //                 order: this.orderID,
    //               }),
    //             })
    //               .then((response) => {
    //                 if (!response.ok) {
    //                   throw new Error(`HTTP error! status: ${response.status}`);
    //                 }
    //                 return response.json();
    //               })
    //               .then((data) => {
    //                 this.alert = true;
    //                 setTimeout(() => {
    //                   this.alert = false; // ваша функция здесь
    //                 }, 3000);
    //                 this.name_customer = "";
    //                 this.phone_number = "";
    //                 this.message = "";
    //                 console.log("Успешно создан новый элемент:", data);
    //               })
    //               .catch((error) => {
    //                 console.error("Ошибка при создании элемента:", error);
    //               });
    //             //******************* */
    //           })
    //           .catch((error) => {
    //             console.error("Ошибка при создании запроса:", error);
    //           });
    //         //******************* */

    //         console.log("Успешно создан новый контакт:", data);
    //       })
    //       .catch((error) => {
    //         this.errorMesage = "Будь ласка перевірте поля ведення даних";
    //         this.error = true;
    //         setTimeout(() => {
    //           this.error = false; // ваша функция здесь
    //         }, 3000);
    //         console.error("Ошибка при создании контакта:", error);
    //       });
    //     //********************************************************************************************************************* */
    //   }
    // },

    // Одним запросом
    // createdLead() {
    //   const url = `${this.API_URL}${"api/v1/create_lead/"}`;
    //   fetch(url, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //     },
    //     body: JSON.stringify({
    //       // Данные нового заказа
    //       name: "Звернення з форми CallBack", // Name Odrer
    //       type: 3, // Type Order
    //       status: 3, // Status Order
    //       from_order: 2, // From Order
    //       last_name: "Заповнити",
    //       status_customer: 1,
    //       comment_customer: "Контакт створено з форми CallBack",
    //       name_item_order: "Элемент звернення CallBack",
    //       number_phone: this.phone_number,
    //       first_name: this.name_customer,
    //       message: this.message,
    //     }),
    //   })
    //     .then((response) => {
    //       if (response.ok) {
    //         return response.json();
    //       } else {
    //         throw new Error("Request failed.");
    //       }
    //     })
    //     .then((data) => {
    //       console.log(data);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    //   // function getCookie(name) {
    //   //   let cookieValue = null;
    //   //   if (document.cookie && document.cookie !== "") {
    //   //     const cookies = document.cookie.split(";");
    //   //     for (let i = 0; i < cookies.length; i++) {
    //   //       const cookie = cookies[i].trim();
    //   //       // Получаем значение csrf-токена из кук
    //   //       if (cookie.substring(0, name.length + 1) === name + "=") {
    //   //         cookieValue = decodeURIComponent(
    //   //           cookie.substring(name.length + 1)
    //   //         );
    //   //         break;
    //   //       }
    //   //     }
    //   //   }
    //   //   return cookieValue;
    //   // }
    // },
  },
};
</script>
