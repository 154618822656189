<template>
  <div class="relative flex items-center">
    <button
    aria-label="Кошик"
      @click="toggle"
      :class="[
        stateCart ? 'text-blue-600' : 'text-primary dark:text-slate-200',
      ]"
      class="cursor-pointer hover:text-blue-600 dark:hover:text-blue-600 transition-all duration-150"
    >
      <CartIcon />
    </button>
    <div
      v-if="cart.length != 0"
      class="flex items-center justify-center h-5 w-5 rounded-full bg-blue-600 text-white text-[10px] absolute left-5 bottom-3"
    >
      <span>{{ cart.length }}</span>
    </div>
    <Transition
      enter-from-class="opacity-0"
      enter-active-class="transition duration-200"
      leave-to-class=" opacity-0"
      leave-active-class="transition duration-200"
    >
      <div
        v-show="stateCart"
        @click="toggle"
        class="fixed z-[100] inset-0 bg-slate-900/60 transition-opacity"
      ></div>
    </Transition>

    <Transition
      enter-from-class="translate-x-full opacity-0"
      enter-active-class="transition duration-200"
      leave-to-class="translate-x-full opacity-0"
      leave-active-class="transition duration-200"
    >
      <div
        style="display: none"
        v-show="stateCart"
        class="fixed text-sm right-0 top-0 h-full overflow-y-auto text-slate-900 border shadow-md bg-white w-full sm:w-[550px] z-[999]"
      >
        <div class="flex justify-between items-center border-b p-4 w-full">
          <h3 class="text-slate-900 font-medium text-base">Кошик</h3>
          <button
            @click="toggle"
            class="text-slate-600 hover:text-slate-900 transition duration-150"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <!--  Если есть товары то показать их -->
        <div v-show="cart.length > 0" class="w-full">
          <div>
            <div class="p-4 items-center hidden">
              <h5 class="font-medium">Товари у кошику</h5>
            </div>

            <div class="px-2 py-4 lg:p-4">
              <table class="w-full text-left">
                <thead class="">
                  <tr class="hidden">
                    <th
                      class="whitespace-nowrap pr-2 py-2 text-xs uppercase text-slate-400"
                    >
                      #
                    </th>
                    <th
                      class="whitespace-nowrap p-2 text-xs uppercase text-slate-400 hidden sm:flex"
                    >
                      Фото
                    </th>
                    <th
                      class="whitespace-nowrap px-1 sm:px-3 py-2 text-xs uppercase text-slate-400"
                    >
                      Модель
                    </th>

                    <th
                      class="whitespace-nowrap px-1 sm:px-4 py-2 text-xs uppercase text-slate-400 text-left"
                    >
                      К-ть
                    </th>
                    <th
                      class="whitespace-nowrap px-1 sm:px-3 py-2 text-xs uppercase text-slate-400 text-left"
                    >
                      Сума
                    </th>
                    <th class="whitespace-nowrap pl-1 sm:pl-4 py-2"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(product, index) in cart" :key="product.id">
                    <td class="whitespace-nowrap pr-2 py-2 text-xs">
                      {{ index + 1 }}
                    </td>
                    <td
                      class="whitespace-nowrap px-1 sm:px-3 py-2 text-xs hidden sm:flex"
                    >
                      <div class="h-8 flex items-center justify-center w-full">
                        <img
                          class="h-full"
                          :src="product.image_main"
                          :alt="product.name"
                        />
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap p-1 sm:p-2 text-xs font-medium"
                    >
                      <router-link
                        :to="'/shop/' + product.slug"
                        @click="toggle"
                        class="hover:text-blue-600 transition duration-150 flex flex-col"
                      >
                        <span>{{ product.model }}</span>
                        <span class="font-normal"> {{ product.name }}</span>
                      </router-link>
                    </td>

                    <td
                      class="whitespace-nowrap px-1 sm:px-4 py-2 text-xs text-left"
                    >
                      <div class="flex items-center w-full justify-center">
                        <div class="w-7 sm:w-9">
                          <button
                            @click="decrementCartQuantity(product)"
                            :class="[
                              product.cart_quantity <= 1
                                ? 'cursor-not-allowed text-slate-400'
                                : 'text-slate-600 hover:text-slate-900 bg-slate-100 hover:bg-slate-200 active:bg-blue-500 active:text-white active:border-blue-500',
                            ]"
                            class="w-full flex p-1 lg:p-0.5 justify-center rounded-l-md border-y border-l border-slate-300 transition duration-105"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18 12H6"
                              />
                            </svg>
                          </button>
                        </div>
                        <div class="w-11">
                          <input
                            v-model.number="product.cart_quantity"
                            @keyup.enter="updateQuntityInCartInput(product)"
                            min="1"
                            type="number"
                            class="block w-full appearance-none text-base lg:text-sm border-slate-300 hover:border-slate-400 transition duration-150 bg-white p-1 text-slate-900 text-center focus:outline-none focus:border-blue-500 focus:ring-0"
                          />
                        </div>
                        <div class="w-7 sm:w-9">
                          <button
                            @click="incrementCartQuantity(product)"
                            class="w-full text-slate-600 hover:text-slate-900 flex p-1 lg:p-0.5 justify-center rounded-r-md border-y border-r border-slate-300 bg-slate-100 hover:bg-slate-200 transition duration-105 active:bg-blue-500 active:text-white active:border-blue-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 6v12m6-6H6"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-1 sm:px-3 py-2 text-base text-left font-medium"
                    >
                      <div class="w-20">
                        {{ product.total_price }}
                        <span class="text-xs lg:text-sm">грн.</span>
                      </div>
                    </td>
                    <td class="whitespace-nowrap pl-1 sm:pl-4 py-2 text-right">
                      <button
                        @click="removeProductFromCart(index, product)"
                        class="text-xs rounded-md text-red-400 hover:text-red-600 text-center flex items-center justify-center transition duration-150"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Очистка корзины -->
            <div class="pb-4 flex items-center relative">
              <div
                :class="[activeClearCart ? 'opacity-0' : '']"
                class="flex justify-end items-center w-full px-4"
              >
                <button
                  @click="activeClearCart = true"
                  class="text-xs text-slate-400 hover:text-red-400 transition duration-150"
                >
                  Очистити кошик
                </button>
              </div>

              <Transition
                enter-from-class="-translate-x-full opacity-0"
                enter-active-class="transition duration-200"
                leave-to-class="-translate-x-full opacity-0"
                leave-active-class="transition duration-200"
              >
                <div
                  v-if="activeClearCart"
                  class="absolute top-0 left-0 flex justify-between items-center w-full pl-4"
                >
                  <span class="text-xs text-red-600"
                    >Всі товари будуть видалені!</span
                  >
                  <div class="flex items-center space-x-4 px-4 text-xs">
                    <button
                      @click="clearAllProductFromCart"
                      class="hover:text-red-500 transition duration-150"
                    >
                      Видалити
                    </button>
                    <button
                      @click="activeClearCart = false"
                      class="text-green-700 hover:text-green-600 transition duration-150"
                    >
                      Скасувати
                    </button>
                  </div>
                </div>
              </Transition>
            </div>
            <!-- Форма оформления заказа -->
            <section>
              <div class="px-2 sm:px-4 pt-2 pb-4">
                <h5 class="font-medium text-sm">Ваші контактні дані</h5>
              </div>
              <div class="px-2 sm:px-4">
                <div>
                  <div
                    class="flex flex-col sm:flex-row items-center w-full space-y-2 sm:space-y-0 sm:space-x-4"
                  >
                    <div class="relative w-full sm:w-1/2 flex items-center">
                      <input
                        class="block peer w-full appearance-none rounded-md border border-slate-300 hover:border-slate-400 transition duration-150 bg-white px-2 sm:px-3 py-1.5 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                        type="text"
                        v-model="last_name_customer"
                        placeholder="Прізвище"
                      />
                      <span
                        class="absolute right-0 w-10 flex items-center justify-center text-slate-400 peer-focus:text-blue-600 transition duration-150"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>
                      </span>
                    </div>
                    <div class="relative w-full sm:w-1/2 flex items-center">
                      <input
                        class="block peer w-full appearance-none rounded-md border border-slate-300 hover:border-slate-400 transition duration-150 bg-white px-2 sm:px-3 py-1.5 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                        type="text"
                        v-model="name_customer"
                        placeholder="Ім'я"
                      />
                      <span
                        class="absolute right-0 w-10 flex items-center justify-center text-slate-400 peer-focus:text-blue-600 transition duration-150"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    class="flex flex-col sm:flex-row items-center w-full space-y-2 sm:space-y-0 sm:space-x-4 mt-2 sm:mt-4"
                  >
                    <div class="relative w-full sm:w-1/2 flex items-center">
                      <input
                        class="block peer w-full appearance-none rounded-md border border-slate-300 hover:border-slate-400 transition duration-150 bg-white px-2 sm:px-3 py-1.5 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                        type="text"
                        v-model="address"
                        placeholder="Місто"
                      />
                      <span
                        class="absolute right-0 w-10 flex items-center justify-center text-slate-400 peer-focus:text-blue-600 transition duration-150"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                          />
                        </svg>
                      </span>
                    </div>
                    <div class="relative w-full sm:w-1/2 flex items-center">
                      <input
                        class="block peer w-full appearance-none rounded-md border border-slate-300 hover:border-slate-400 transition duration-150 bg-white pr-2 sm:pr-3 pl-12 sm:pl-14 py-1.5 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                        id="phone_number_cart"
                        v-model="phone_number"
                        v-bind:maxlength="9"
                        v-on:keypress="validatePhoneNumber"
                        type="tel"
                        placeholder="Телефон"
                      />
                      <span
                        class="absolute right-0 w-10 flex items-center justify-center text-slate-400 peer-focus:text-blue-600 transition duration-150"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                          />
                        </svg>
                      </span>
                      <span
                        class="mt-px absolute text-base top-1.5 flex items-center justify-center text-slate-400 peer-focus:text-slate-900 transition duration-150 left-2 sm:left-3"
                        >+380</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="pt-4 sm:pt-8 flex flex-col sm:flex-row sm:justify-between items-center mt-2 sm:mt-4"
                >
                  <h2 class="font-medium">
                    <span class="text-base text-slate-600 font-normal pr-2"
                      >Загальна сума:</span
                    >
                    {{ cart_total_price }} грн.
                  </h2>
                  <div class="mt-8 sm:mt-0">
                    <button
                      @click="createdNewOrderProduct"
                      class="text-sm rounded-full bg-blue-600 text-white text-center px-12 py-3 hover:bg-blue-600/90 active:bg-blue-600 transition duration-150"
                    >
                      Підтвердити
                    </button>
                  </div>
                </div>
                <div
                  v-if="error"
                  class="text-center px-6 py-2 rounded-xl bg-red-600 text-white mt-4"
                >
                  {{ errorMessage }}
                </div>
              </div>
            </section>
          </div>
        </div>
        <!--  Если товаров к корзине нет -->
        <div v-show="cart.length == 0" class="w-full">
          <div class="p-4 w-full">
            <h5 class="font-medium">Ваш кошик порожній</h5>
            <Transition
              enter-from-class="opacity-0 scale-95"
              enter-active-class="transition duration-200"
              leave-to-class="opacity-0 scale-95"
              leave-active-class="transition duration-200"
            >
              <div
                v-if="alert"
                class="mt-8 flex items-center justify-center p-4 rounded-xl bg-green-600 text-white text-sm"
              >
                {{ messageAlert }}
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import CartIcon from "@/components/icons/CartIcon";
import { mapState, mapMutations } from "vuex";

export default {
  name: "ButtonCartHeader",
  components: {
    CartIcon,
  },
  computed: {
    ...mapState(["stateCart", "cart", "cart_total_price", "API_URL"]),
  },
  data() {
    return {
      alert: false,
      error: false,
      name_customer: "",
      last_name_customer: "",
      phone_number: "",
      address: "",
      errorMessage: "",
      messageAlert: "",
      // customerInBase: null,
      // allCustomersData: [],
      // orderID: null,
      // customerID: null,

      activeClearCart: false, // Кнопка очистки кошика від усіх товарів
    };
  },
  mounted() {
    this.getCartData();
    this.calcTotalCartPrice();
  },
  methods: {
    ...mapMutations([
      "updateStateCart",
      "getCartData",
      "calcTotalCartPrice",
      "removeFromCart",
      "saveCart",
      "clearCart",
      "updateProductData",
      "updateProductsData",
      "updateQuntityProductsData",
      "updateQuantityProductInCart",
      "setIsPageActive",
    ]),

    validatePhoneNumber(event) {
      const keyCode = event.keyCode;
      const excludedKeys = [8, 37, 39, 46];
      if (
        (event.shiftKey === true && keyCode === 32) ||
        (keyCode === 32 && this.phone_number === "") ||
        excludedKeys.includes(keyCode)
      ) {
        event.preventDefault();
      } else {
        if (this.phone_number.length > 9) {
          event.preventDefault();
        } else {
          const regex = /^[0-9]+$/;
          const inputChar = String.fromCharCode(keyCode);
          if (!regex.test(inputChar)) {
            event.preventDefault();
          }
        }
      }
    },
    // Одним запросом
    createdNewOrderProduct() {
      if (this.name_customer != "") {
        if (this.phone_number.length < 9) {
          this.errorMessage = "Будь ласка перевірьте номер телефону";
          this.error = true;
          setTimeout(() => {
            this.error = false; // ваша функция здесь
          }, 3000);
        } else {
          const url = `${this.API_URL}${"api/v1/create-products-order/"}`;
          const data = {
            // Данные нового заказа
            name: "Замовлення товарів з интернету",
            type: 1,
            status: 1,
            from_order: 1,
            status_finance: 1,
            total_price: this.cart_total_price,
            comment:
              "Данні в замовлені: " +
              "Прізвище - " +
              this.last_name_customer +
              ", Ім'я - " +
              this.name_customer +
              ", Адреса - " +
              this.address +
              ", Телефон - " +
              "+380" +
              this.phone_number,

            first_name: this.name_customer,
            number_phone: this.phone_number,
            status_customer: 1,
            last_name: this.last_name_customer,
            address: this.address,
            comment_customer: "Контакт створено з форми Корзини",
            cart: this.cart,
          };
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((data) => {
              // Очистка данных заказчика в полях инпут и очистка корзины
              this.alert = true;
              this.messageAlert =
                "Ваше замовлення оформлено. Очикуйте на зворотній дзвінок!";
              setTimeout(() => {
                this.alert = false; // ваша функция здесь
              }, 3000);
              this.name_customer = "";
              this.phone_number = "";
              this.last_name_customer = "";
              this.address = "";
              this.clearAllProductFromCart();
              console.log("Успешно создан новый элемент:", data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.errorMessage = "Будь ласка перевірьте ім'я";
        this.error = true;
        setTimeout(() => {
          this.error = false; // ваша функция здесь
        }, 3000);
      }
    },
    // Тремя запросами
    // async createNewOrderProduct() {
    //   const url1 = `${this.API_URL}${"api/v1/customers/"}`;
    //   const response1 = await fetch(url1);
    //   const data1 = await response1.json();
    //   this.allCustomersData = data1;
    //   console.log("data customers " + this.allCustomersData);
    //   const customer = this.allCustomersData.find(
    //     (cust) => cust.phone_number === this.phone_number
    //   );
    //   console.log("data customer " + customer);
    //   if (customer) {
    //     console.log(
    //       `Customer found! Name: ${customer.name}, Phone: ${customer.id}`
    //     );
    //     // **********************
    //     const url2 = `${this.API_URL}${"api/v1/new-orders/"}`;
    //     fetch(url2, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         name: "Замовлення товарів з интернету",
    //         type: 1,
    //         status: 1,
    //         from_order: 1,
    //         status_finance: 1,
    //         customer: customer.id,
    //         total_price: this.cart_total_price,
    //         comment:
    //           "Данні в замовлені: " +
    //           "Прізвище - " +
    //           this.last_name_customer +
    //           ", Ім'я - " +
    //           this.name_customer +
    //           ", Адреса - " +
    //           this.address +
    //           ", Телефон - " +
    //           "+380" +
    //           this.phone_number,
    //       }),
    //     })
    //       .then((response) => {
    //         if (!response.ok) {
    //           throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    //         return response.json();
    //       })
    //       .then((data) => {
    //         this.orderID = data.id;
    //         console.log("orderID - " + this.orderID);
    //         console.log("Успешно создан новый заказ на товар:", data);
    //         console.log("in cart: " + this.cart.length);
    //         // **********************
    //         this.cart.forEach((item) => {
    //           console.log("item: " + item);
    //           const url3 = `${this.API_URL}${"api/v1/new-item-order-product/"}`;
    //           fetch(url3, {
    //             method: "POST",
    //             headers: {
    //               "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //               name: "Элемент интернет замовлення",
    //               order: this.orderID,
    //               product: item.id,
    //               quantity: item.quantity,
    //               price: item.price_ua,
    //               total_price: item.total_price,
    //             }),
    //           })
    //             .then((response) => {
    //               if (!response.ok) {
    //                 throw new Error(`HTTP error! status: ${response.status}`);
    //               }
    //               return response.json();
    //             })
    //             .then((data) => {
    //               console.log("Успешно создан новый элемент:", data);
    //             })
    //             .catch((error) => {
    //               console.error("Ошибка при создании элемента:", error);
    //             });
    //         });
    //         // Очистка данных заказчика в полях инпут и очистка корзины
    //         this.alert = true;
    //         this.messageAlert =
    //           "Ваше замовлення оформлено. Очикуйте на зворотній дзвінок!";
    //         setTimeout(() => {
    //           this.alert = false; // ваша функция здесь
    //         }, 3000);
    //         this.name_customer = "";
    //         this.phone_number = "";
    //         this.last_name_customer = "";
    //         this.address = "";
    //         this.clearAllProductFromCart();
    //         //******************* */
    //       })
    //       .catch((error) => {
    //         console.error("Ошибка при создании заказа:", error);
    //       });
    //     //******************* */
    //   } else {
    //     console.log(`Customer not found for phone number ${this.phone_number}`);
    //     // ****************************************************************************************************************************
    //     const url4 = `${this.API_URL}${"api/v1/new-customers/"}`;
    //     fetch(url4, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         name: this.name_customer,
    //         phone_number: this.phone_number,
    //         status: 1,
    //         last_name: this.last_name_customer,
    //         e_mail: "",
    //         address: this.address,
    //         comment: "Контакт створено з форми Корзини",
    //       }),
    //     })
    //       .then((response) => {
    //         if (!response.ok) {
    //           throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    //         return response.json();
    //       })
    //       .then((data) => {
    //         this.customerID = data.id;
    //         console.log("customerID - " + this.customerID);
    //         // **********************
    //         const url5 = `${this.API_URL}${"api/v1/new-orders/"}`;
    //         fetch(url5, {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //           body: JSON.stringify({
    //             name: "Замовлення товарів з интернету",
    //             type: 1,
    //             status: 1,
    //             from_order: 1,
    //             status_finance: 1,
    //             customer: this.customerID,
    //             total_price: this.cart_total_price,
    //             comment:
    //               "Данні в замовлені: " +
    //               "Прізвище - " +
    //               this.last_name_customer +
    //               ", Ім'я - " +
    //               this.name_customer +
    //               ", Адреса - " +
    //               this.address +
    //               ", Телефон - " +
    //               "+380" +
    //               this.phone_number,
    //           }),
    //         })
    //           .then((response) => {
    //             if (!response.ok) {
    //               throw new Error(`HTTP error! status: ${response.status}`);
    //             }
    //             return response.json();
    //           })
    //           .then((data) => {
    //             this.orderID = data.id;
    //             console.log("orderID - " + this.orderID);
    //             console.log("Успешно создан новый заказ на товары:", data);
    //             console.log("in cart: " + this.cart);
    //             // **********************
    //             this.cart.forEach((item) => {
    //               const url6 = `${
    //                 this.API_URL
    //               }${"api/v1/new-item-order-product/"}`;
    //               fetch(url6, {
    //                 method: "POST",
    //                 headers: {
    //                   "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify({
    //                   name: "Элемент интернет замовлення",
    //                   order: this.orderID,
    //                   product: item.id,
    //                   quantity: item.quantity,
    //                   price: item.price_ua,
    //                   total_price: item.total_price,
    //                 }),
    //               })
    //                 .then((response) => {
    //                   if (!response.ok) {
    //                     throw new Error(
    //                       `HTTP error! status: ${response.status}`
    //                     );
    //                   }
    //                   return response.json();
    //                 })
    //                 .then((data) => {
    //                   console.log(
    //                     "Успешно создан новый элемент заказа на товары:",
    //                     data
    //                   );
    //                 })
    //                 .catch((error) => {
    //                   console.error(
    //                     "Ошибка при создании элемента заказа на товары:",
    //                     error
    //                   );
    //                 });
    //             });
    //             // Очистка данных заказчика в полях инпут и очистка корзины
    //             this.alert = true;
    //             this.messageAlert =
    //               "Ваше замовлення оформлено. Очикуйте на зворотній дзвінок!";
    //             setTimeout(() => {
    //               this.alert = false; // ваша функция здесь
    //             }, 3000);
    //             this.name_customer = "";
    //             this.phone_number = "";
    //             this.last_name_customer = "";
    //             this.address = "";
    //             this.clearAllProductFromCart();
    //             //******************* */
    //           })
    //           .catch((error) => {
    //             console.error("Ошибка при создании заказа на товары:", error);
    //           });
    //         //******************* */

    //         console.log("Успешно создан новый контакт:", data);
    //       })
    //       .catch((error) => {
    //         this.errorMesage = "Будь ласка перевірте поля ведення даних";
    //         this.error = true;
    //         setTimeout(() => {
    //           this.error = false; // ваша функция здесь
    //         }, 3000);
    //         console.error("Ошибка при создании контакта:", error);
    //       });
    //     //********************************************************************************************************************* */
    //   }
    // },

    // Кнопка в корзине товаров плюс количество
    incrementCartQuantity(product) {
      product.cart_quantity++;
      product.quantity = product.cart_quantity;
      product.total_price = (product.cart_quantity * product.price_ua).toFixed(
        0
      );
      this.calcTotalCartPrice();
      this.saveCart(); // Сохраняем корзину в КЕШ
      // Меняем кол-во товаров продукта в каталоге равное кол-ву товаров в корзине
      this.updateQuntityProductsData(product);
    },

    // Кнопка в корзине товаров минус количество
    decrementCartQuantity(product) {
      if (product.cart_quantity > 1) {
        product.cart_quantity--;
        product.quantity = product.cart_quantity;
        product.total_price = (
          product.cart_quantity * product.price_ua
        ).toFixed(0);
        this.calcTotalCartPrice();
        this.saveCart(); // Сохраняем корзину в КЕШ
        // Меняем кол-во товаров продукта в каталоге равное кол-ву товаров в корзине
        this.updateQuntityProductsData(product);
      }
    },

    // Меняем кол-во товаров по input
    updateQuntityInCartInput(product) {
      this.updateQuantityProductInCart(product);
      this.calcTotalCartPrice();
      this.saveCart(); // Сохраняем корзину в КЕШ
    },

    // Удаление одного товара из корзины
    removeProductFromCart(index, product) {
      this.removeFromCart(index);
      this.calcTotalCartPrice();
      this.saveCart();
      this.updateProductData(product);
    },

    clearAllProductFromCart() {
      this.clearCart();
      this.saveCart();
      this.updateProductsData();
      this.activeClearCart = false;
    },

    toggle() {
      this.updateStateCart(!this.stateCart);
      this.userLastName = "";
      this.userName = "";
      this.userNumberPhone = "";
      this.userSity = "";
      this.activeClearCart = false;
    },
  },
};
</script>
