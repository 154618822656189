import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/services",
    name: "services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/ServicesView.vue"),
  },
  {
    path: "/services/:slug",
    name: "servicepage",
    component: () =>
      import(
        /* webpackChunkName: "servicepage" */ "../views/ServicePageView.vue"
      ),
  },
  {
    path: "/shop",
    name: "shop",
    component: () =>
      import(/* webpackChunkName: "shop" */ "../views/ShopView.vue"),
  },

  {
    path: "/shop/:slug",
    name: "productPage",
    component: () =>
      import(
        /* webpackChunkName: "productPage" */ "../views/ProductPageView.vue"
      ),
  },
  {
    path: "/documentations",
    name: "documentations",
    component: () =>
      import(
        /* webpackChunkName: "documentations" */ "../views/DocumentationsView.vue"
      ),
  },

  {
    path: "/documentations/:slug",
    name: "document",
    component: () =>
      import(/* webpackChunkName: "document" */ "../views/DocView.vue"),
  },

  {
    path: "/documentations/step-by-step",
    name: "stepbystep",
    component: () =>
      import(
        /* webpackChunkName: "stepbystep" */ "../views/StepByStepView.vue"
      ),
  },

  {
    path: "/documentations/step-by-step/:slug",
    name: "steps",
    component: () =>
      import(/* webpackChunkName: "steps" */ "../views/StByStDoc.vue"),
  },

  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactsView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/crm/customers",
    name: "crmCustomers",
    component: () =>
      import(
        /* webpackChunkName: "crmCustomers" */ "../views/crm/CustomersListView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },
  {
    path: "/crm/orders",
    name: "crmOrders",
    component: () =>
      import(
        /* webpackChunkName: "crmOrders" */ "../views/crm/OrdersListView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },
  {
    path: "/crm/objects",
    name: "crmObjects",
    component: () =>
      import(
        /* webpackChunkName: "crmObjects" */ "../views/crm/ObjectsListView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },
  {
    path: "/crm/new-objects",
    name: "crmNewObjects",
    component: () =>
      import(
        /* webpackChunkName: "crmNewObjects" */ "../views/crm/NewObjectView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },
  {
    path: "/crm/object-page",
    name: "crmObjectPage",
    component: () =>
      import(
        /* webpackChunkName: "crmObjectPage" */ "../views/crm/ObjectsPage.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },
  {
    path: "/crm/new-order",
    name: "crmNewOrder",
    component: () =>
      import(
        /* webpackChunkName: "crmNewOrder" */ "../views/crm/CrmNewOrderView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },
  {
    path: "/crm/customer-page",
    name: "customerPage",
    component: () =>
      import(
        /* webpackChunkName: "customerPage" */ "../views/crm/CustomerPage.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },
  {
    path: "/crm/new-customer",
    name: "newCustomer",
    component: () =>
      import(
        /* webpackChunkName: "newCustomer" */ "../views/crm/NewCustomerView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },

  {
    path: "/crm/update-customer",
    name: "updateCustomer",
    component: () =>
      import(
        /* webpackChunkName: "updateCustomer" */ "../views/crm/UpdateCustomerView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },

  {
    path: "/crm/order-page",
    name: "orderPage",
    component: () =>
      import(
        /* webpackChunkName: "orderPage" */ "../views/crm/OrderPageView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },

  {
    path: "/crm/finance",
    name: "financePage",
    component: () =>
      import(
        /* webpackChunkName: "financePage" */ "../views/crm/CrmFinanceView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },

  {
    path: "/crm/new-finance",
    name: "financeNewPage",
    component: () =>
      import(
        /* webpackChunkName: "financeNewPage" */ "../views/crm/NewFinance.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },

  {
    path: "/crm/notes",
    name: "notesPage",
    component: () =>
      import(
        /* webpackChunkName: "notesPage" */ "../views/crm/CrmNoteView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },

  {
    path: "/crm/new-notes",
    name: "notesNewPage",
    component: () =>
      import(
        /* webpackChunkName: "notesNewPage" */ "../views/crm/NewNotesView.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },

  // Тестовая страница
  {
    path: "/crm/test",
    name: "testPage",
    component: () =>
      import(
        /* webpackChunkName: "notesPage" */ "../views/crm/CrmTestPage.vue"
      ),
    meta: { requiresAuth: true }, //  ограничения доступа
  },

  {
    path: "/:catchAll(.*)",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/Error404.vue"),
  }, // компонент для ошибки 404
];

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
