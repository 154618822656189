<template>
  <svg
    class="w-7 h-7"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.5 24C47.5 36.9787 36.9787 47.5 24 47.5C11.0213 47.5 0.5 36.9787 0.5 24C0.5 11.0213 11.0213 0.5 24 0.5C36.9787 0.5 47.5 11.0213 47.5 24Z"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.1061 24.1789C19.5671 25.6247 19.2049 27.5551 19.1263 29.4547C13.3739 26.3277 12.8186 22.9206 12.3134 19.8208C11.975 17.7443 11.6591 15.8057 9.81836 14.1816C11.1764 14.7003 12.4298 14.8995 13.6346 15.0909C16.5772 15.5587 19.2307 15.9804 22.4148 20.8977C21.4185 21.8616 20.6516 22.9578 20.1061 24.1789ZM20.0425 27.6775C20.6397 21.6465 26.0089 17.8841 31.7467 17.7557C34.7667 17.6881 37.8881 18.6273 40.4698 20.7721C37.8374 21.0676 36.4199 22.9282 34.8525 24.9856C32.3193 28.3108 29.3948 32.1496 20.3168 30.7234C20.2895 30.8116 20.262 30.8992 20.2345 30.9865C19.8672 32.1546 19.511 33.2872 19.7693 35.187C19.4884 35.5707 18.8236 35.4403 18.724 35.1663C18.6068 33.2426 19.2041 31.9012 20.1335 30.6959C19.9736 29.6365 19.9481 28.6287 20.0425 27.6775Z"
      fill="currentColor"
    />
  </svg>
</template>
