<template>
  <div>
    <!-- HERO -->

    <HeroBlock :data="homePageHeroBlock" />

    <!-- SERVISES -->
    <section class="relative overflow-hidden bg-blue-600 py-20 lg:py-32">
      <div class="container sm:px-6 lg:px-8 relative">
        <div class="max-w-2xl md:mx-auto text-center xl:max-w-none">
          <h2 class="text-3xl text-white sm:text-4xl md:text-5xl">
            {{ homePageServicesBlock.header_first }}
          </h2>
          <p class="mt-6 md:text-lg tracking-tight text-white">
            {{ homePageServicesBlock.title }}
          </p>
        </div>
        <div class="mt-16 grid grid-cols-1 items-center gap-6 lg:gap-10 md:mt-20 sm:grid-cols-2 lg:grid-cols-3">
          <router-link v-for="service in allServisesData" :key="service" :to="'/services/' + service.slug">
            <div
              class="text-white bg-white/5 p-8 rounded-xl ring-1 ring-white/5 ring-inset hover:bg-white/10 hover:ring-white/10 flex items-center space-x-8 transition duration-150">
              <div class="h-10 lg:h-14">
                <img class="h-10 lg:h-14 w-10 lg:w-14 group-hover:scale-105 transition duration-150"
                  :src="service.icon_white" :alt="service.name" />
              </div>
              <div>
                <h3 class="text-lg">{{ service.name }}</h3>
                <p class="mt-2 text-sm block">
                  {{ service.header_first }} {{ service.header_second }}
                </p>
              </div>
            </div>
          </router-link>
        </div>

        <div class="mx-auto max-w-lg xl:max-w-2xl text-center pt-20">
          <h2 class="text-3xl tracking-tight text-white sm:text-4xl">
            Самостійний монтаж та обслуговування
          </h2>
          <p class="mt-4 md:text-lg tracking-tight text-white max-w-xl mx-auto">
            Покрокові інструкції з проєктування та монтажу систем зрошення.
            Інструкції до обладнання та обслуговування систем.
          </p>
          <router-link class="btn-secondary-2 mt-10 w-48" to="/documentations">Розпочати</router-link>
        </div>
      </div>
    </section>
    <!-- PRODUCTS -->
    <section class="py-20 sm:py-32">
      <div class="container sm:px-6 lg:px-8">
        <div class="max-w-2xl md:mx-auto text-center xl:max-w-none">
          <h2 class="text-3xl  sm:text-4xl md:text-5xl">
            {{ homePageProductsBlock.header_first }}
          </h2>
          <p class="mt-6 md:text-lg tracking-tight text-[#142251]/80">
            {{ homePageProductsBlock.title }}
          </p>
        </div>
        <div class="mt-16 grid grid-cols-1 items-center gap-6 lg:gap-10 md:mt-20 sm:grid-cols-2 lg:grid-cols-3">
          <router-link v-for="product in categoryProductsData" :key="product.id" :to="{
            path: '/shop',
            query: { myParam: product.id, myParam_h: product.header },
          }" class="group">
            <div
              class="p-8 rounded-xl hover:ring-1 ring-inset hover:bg-blue-900/5 hover:ring-blue-900/5 flex items-center space-x-8 transition duration-150">
              <div class="h-10  lg:h-14">
                <img class="h-10 w-10 lg:w-14 lg:h-14 group-hover:scale-105 transition duration-150" :src="product.icon"
                  :alt="product.name" />
              </div>
              <div>
                <h3 class="text-lg font-medium">
                  {{ product.name }}
                </h3>
                <p class="mt-2 text-sm block text-slate-700">
                  {{ product.title }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
        <!-- <div class="hidden mx-auto max-w-sm text-center mt-10 md:mt-20">
          <a class="btn-primary" href="#">Перейти до магазину</a>
        </div> -->
      </div>
    </section>
    <!-- PARTNERS BANNER -->
    <section class="relative overflow-hidden bg-blue-600 py-20 lg:py-32">
      <img alt="Фон банера" src="@/assets/images/background-partners-banner-block.webp" width="2347" height="1244"
        decoding="async" data-nimg="1"
        class="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2 hidden sm:block" loading="lazy"
        style="color: transparent" />

      <div class="container sm:px-6 lg:px-8 relative">
        <div class="mx-auto max-w-lg text-center">
          <h2 class="text-3xl tracking-tight text-white sm:text-4xl">
            {{ homePagePartnersBlock.header_first }}
          </h2>
          <p class="mt-4 md:text-lg tracking-tight text-white">
            {{ homePagePartnersBlock.title }}
          </p>
          <router-link to="/contact" class="btn-secondary-2 mt-10 w-48" @click="setIsPageActive('')">Зв'язатись з
            нами</router-link>
        </div>
      </div>
    </section>
    <!-- FAQ -->
    <section id="faq" aria-labelledby="faq-title" class="relative overflow-hidden bg-slate-50 py-20 sm:py-32">
      <img alt="Фон Питання та відповідь" src="@/assets/images/background-faq.webp" width="1558" height="946"
        decoding="async" data-nimg="1"
        class="absolute top-0 left-1/2 max-w-none translate-x-[-30%]  -translate-y-1/4 hidden sm:block" loading="lazy"
        style="color: transparent" />
      <div class="container sm:px-6 lg:px-8 relative">
        <div class="mx-auto max-w-2xl md:max-w-none lg:mx-0 text-center">
          <h2 id="faq-title" class="font-medium text-3xl tracking-tight sm:text-4xl">
            {{ homePageFaqBlock.header_first }}
          </h2>
          <p class="mt-4 md:text-lg tracking-tight text-[#142251]/80">
            {{ homePageFaqBlock.title }}
          </p>
        </div>
        <!-- Відображення FAQ -->
        <div class="mt-16 mx-auto max-w-2xl md:max-w-none grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div v-for="faq in homePageFaqDate" :key="faq">
            <h3 class="font-medium text-lg leading-7 ">
              {{ faq.question }}
            </h3>
            <p class="mt-4 text-sm text-[#142251]/80">
              {{ faq.answer }}
            </p>
          </div>
        </div>
        <!--  CONTACT FORM -->
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 mt-16 lg:mt-24">
          <div class="col-span-1 space-y-8 lg:space-y-8">
            <div>
              <h3 class="text-lg font-medium leading-6 ">
                Зворотній зв'язок
              </h3>
              <p class="mt-1 text-sm text-[#142251]/80">
                Заповніть форму або зв'яжіться з нами зручним для вас засобом
              </p>
            </div>
            <div class="grid grid-cols-2 lg:grid-cols-1 gap-4">
              <a href="#" class="text-sm lg:text-base hover:text-blue-600 group transition-all duration-150">
                <div class="flex items-center space-x-2 lg:space-x-8 justify-start">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5 text-[#142251]/80 group-hover:text-blue-600">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                    <title>Телефон</title>
                  </svg>
                  <span>+38 050 855 13 48</span>
                </div>
              </a>
              <a href="#" class="text-sm lg:text-base hover:text-blue-600 group transition-all duration-150">
                <div class="flex items-center space-x-2 lg:space-x-8 justify-end sm:justify-start">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5 text-[#142251]/80 group-hover:text-blue-600">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    <title>e-mail</title>
                  </svg>
                  <span>info@aquadecor.ua</span>
                </div>
              </a>
            </div>
          </div>
          <div class="col-span-1 lg:col-span-2 mt-8 lg:mt-0">
            <div class="w-full">
              <!--  FORM CALL BACK HERO  -->
              <CallBackForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import CallBackForm from "@/components/CallBackForm";
import HeroBlock from "@/components/mainpage/HeroBlock";
import { mapState, mapMutations } from "vuex";
export default {
  name: "HomeView",
  components: {
    CallBackForm,
    HeroBlock,
  },
  data() {
    return {};
  },
  created() { },
  computed: {
    ...mapState([
      "categoryProductsData",
      "allServisesData",
      "homePageData",
      "homePageHeroBlock",
      "homePageServicesBlock",
      "homePageProductsBlock",
      "homePagePartnersBlock",
      "homePageFaqBlock",
      "homePageFaqDate",
    ]),
  },
  mounted() {
    document.title = "Aquadecor - професійні системи автоматичного зрошення";
  },

  methods: {
    ...mapMutations(["setIsPageActive"]),
  },
};
</script>
