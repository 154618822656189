<template>
  <header>
    <div class="fixed w-full bg-white z-[103] dark:bg-slate-800">
      <div
        :class="[autorized ? 'pl-24 pr-8' : 'container sm:px-6 lg:px-8']"
        class=""
      >
        <div class="flex justify-between items-center py-6">
          <!--  LOGO  -->
          <div class="flex justify-start items-center text-primary">
            <router-link
              class="inline-flex items-center"
              to="/"
              @click="setIsPageActive('')"
            >
              <div class="p-0.5 rounded-full dark:bg-slate-200">
                <LogoIcon />
              </div>

              <h2 class="ml-1.5 sm:ml-4 text-2xl font-bold dark:text-slate-200">
                AQUADECOR
              </h2>
            </router-link>
          </div>

          <NavHeader /><!--  NAVIGATION  -->
          <!--  ACTIONS  -->
          <div class="flex justify-end items-center gap-x-4 sm:gap-x-8 text-primary">
            <ButtonPhoneHeader /><!-- PHONE BUTTON  -->

            <ButtonCartHeader /><!-- CART SHOP BUTTON  -->

            <ButtonLoginHeader /><!--  LOGIN  -->

            <MobileMenu /><!--  MOBILE MENU  -->
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import ButtonCartHeader from "@/components/header/ButtonCartHeader";
import ButtonLoginHeader from "@/components/header/ButtonLoginHeader";
import ButtonPhoneHeader from "@/components/header/ButtonPhoneHeader";
import MobileMenu from "@/components/header/MobileMenu";
import NavHeader from "@/components/header/NavHeader";
import LogoIcon from "@/components/icons/LogoIcon.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "HeaderMain",
  components: {
    ButtonCartHeader,
    ButtonLoginHeader,
    ButtonPhoneHeader,
    NavHeader,
    MobileMenu,
    LogoIcon,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["autorized"]),
  },
  methods: {
    ...mapMutations(["setIsPageActive"]),
  },

  mounted() {},
};
</script>
