import { createStore } from "vuex";

export default createStore({
  state: {
    // Попапы кнопок в шапке
    statePhone: false, // Попап телефон закрыто
    stateCart: false, // Попап корзина закрыто
    stateLogin: false, // Модальное окно авторизации
    stateLogOut: false, // Модальное окно выхода из акаунта
    stateOrderServices: false, // Попап модальное окно заказа на услугу
    cart: [], // начальный пустой массив для хранения товаров в корзине
    cart_total_price: 0, // Загальна вартість корзини
    categoryProductsData: [], // Категории продуктов
    allProductsData: [], // Все товары
    contactPageAboutData: {},
    contactPageHeroData: {},
    allDocData: [],
    selectDoc: {},
    allServisesData: [],
    projectData: {},
    montagData: {},
    servicData: {},
    remontData: {},
    zapuskData: {},
    zimaData: {},
    homePageData: [],
    homePageHeroBlock: {},
    homePageServicesBlock: {},
    homePageProductsBlock: {},
    homePagePartnersBlock: {},
    homePageFaqBlock: {},
    homePageFaqDate: [],
    isPageActive: "", // Активная ссылка страницы
    autorized: null, // Авторизован чи ні
    stateAcountModal: false, // Состояние модального окна
    profileAuthAcount: {}, // Профиль зарегистрированного пользователя
    profileData: {}, // Данніе профиля зарегистрированного пользователя
    discountData: [], // Данные про скидки
    exchangeData: [], // Данные про курсы валют
    API_URL: "https://admin.aquadecor.ua/",
    //API_URL: "http://127.0.0.1:8000/",
    itemsFinance: [], // Элементы финансов в заказе
    startCalcTotalBalans: false, // Запускаем функцию пересчета баланса финансов в заказе после добавлениянового платежа через wach
    stateQuickSearch: 0,
    activeDoc: 1,
    showDropdownServices: false,
    showDropdownProducts: false,
  },

  getters: {
    cartItems: (state) => state.cart, // получение всех товаров в корзине
  },

  mutations: {
    setShowDropdownServices(state, value) {
      state.showDropdownServices = value;
    },

    setShowDropdownProducts(state, value) {
      state.showDropdownProducts = value;
    },

    setActiveDoc(state, value) {
      state.activeDoc = value;
    },

    setQuickSearch(state, value) {
      state.stateQuickSearch = value;
    },
    resetItemsFinance(state) {
      state.itemsFinance = [];
    },
    updateStartCalcTotalBalans(state) {
      state.startCalcTotalBalans = !state.startCalcTotalBalans;
    },
    // Сохраняем курси валют
    saveExchangeData(state, data) {
      state.exchangeData = data;
    },
    // Сохраняем скидки
    saveDiscountData(state, data) {
      state.discountData = data;
    },

    // Состояние модального окна выхода из акаунта
    updateStateLogOut(state, value) {
      if (value === "true") {
        state.stateLogOut = true;
      } else {
        state.stateLogOut = false;
      }
    },

    // Запись профиля зарегистрированного пользователя
    setProfileData(state, value) {
      state.profileData = value;
    },
    // Запись данных зарегистррованного пользвателя
    setAuthUser(state, data) {
      state.profileAuthAcount = data;
    },
    // Функция открытия закрытия модального окна аакаунта
    toggleAcount(state, value) {
      if (value === "true") {
        state.stateAcountModal = true;
      } else {
        state.stateAcountModal = false;
      }
    },
    // Функция проверки авторизации в локале
    getAutorizerdtData(state) {
      if (localStorage.getItem("authenticated")) {
        // console.log(localStorage.getItem("authenticated"));
        state.autorized = JSON.parse(localStorage.getItem("authenticated"));
      }
    },
    // Функция записи авторизации в локал
    setAutorizerdtData(state, value) {
      if (value === "true") {
        localStorage.setItem("authenticated", true);
        state.autorized = true;
      } else {
        localStorage.setItem("authenticated", false);
        state.autorized = false;
      }
    },

    setIsPageActive(state, value) {
      state.isPageActive = value;
      state.showDropdownServices = false;
      state.showDropdownProducts = false;
      console.log(state.isPageActive)
    },
    // Апдейт статуса попап акаунт
    updateStateLogin(state, value) {
      state.stateLogin = value;
      if (state.stateLogin) {
        state.statePhone = false;
        state.stateCart = false;
      }
    },

    // Апдейт статуса попап телефон
    updateStatePhone(state, value) {
      state.statePhone = value;
      if (state.statePhone) {
        state.stateLogin = false;
        state.stateCart = false;
      }
    },

    // Апдейт статуса попап корзина
    updateStateCart(state, value) {
      state.stateCart = value;
      if (state.stateCart) {
        state.stateLogin = false;
        state.statePhone = false;
      }
    },

    // Открыть - закрыть модальное заказ на услугу
    updateStateOrderServices(state) {
      state.stateOrderServices = !state.stateOrderServices;
    },

    // Добавление товаров в карзину
    addToCart(state, product) {
      if (product.quantity >= 1) {
        product.total_price = (product.quantity * product.price_ua).toFixed(0);
        product.cart_quantity = product.quantity; // Запоминаем колличество в корзине

        // Проверяем статус корзины
        if (state.cart.length > 0) {
          // Проверяем есть ли выбранный товар в корзине
          if (product.in_cart) {
            state.cart.forEach((item) => {
              if (item.id === product.id) {
                item.cart_quantity = product.cart_quantity;
                item.total_price = product.total_price;
                item.quantity = product.cart_quantity;
              }
            });

            console.log("OK - Кількість товару у кошику оновлено"); // Выводим сообщение в лог
          } else {
            state.cart.push({
              id: product.id,
              model: product.model,
              name: product.name,
              price_ua: product.price_ua,
              category: product.category,
              image_main: product.image_main,
              slug: product.slug,
              cart_quantity: product.cart_quantity,
              in_cart: true,
              quantity: product.cart_quantity,
              anim_update: false,
              total_price: (product.price_ua * product.cart_quantity).toFixed(
                0
              ),
            });

            console.log("ITS OK - Новий товар додано у кошик"); // Выводим сообщение в лог
          }
        } else {
          // Добавляем первый товар в корзину и обновляем статус корзины на труе
          state.cart.push({
            id: product.id,
            model: product.model,
            name: product.name,
            price_ua: product.price_ua,
            category: product.category,
            image_main: product.image_main,
            slug: product.slug,
            cart_quantity: product.cart_quantity,
            in_cart: true,
            quantity: product.cart_quantity,
            anim_update: false,
            total_price: (product.price_ua * product.cart_quantity).toFixed(0),
          });

          // console.log("ITS OK - Перший товар додано до кошику"); // Выводим сообщение в лог
        }

        product.anim_update = true;
        setTimeout(function () {
          product.anim_update = false; // ваша функция здесь
        }, 1000);
        product.in_cart = true; // Устанавливаем статус товара в корзине на труе
      } else {
        if (product.in_cart) {
          product.quantity = product.cart_quantity;
        } else {
          product.quantity = 1;
        }

        // console.log(
        //   "ERROR - Кількість товарів <= 0, товар до корзини не додано"
        // );
      }
    },

    // Функция записи массива товаров
    saveProductsData(state, products) {
      state.allProductsData = products;

      // console.log("Продукты - " + state.allProductsData);
    },

    // Функция записи массива категории товаров
    savedCategoryData(state, category) {
      state.categoryProductsData = category;

      // console.log("Категории - " + state.categoryProductsData);
    },

    // Функция проверки корзины в локал и если она есть то запись в масив cart
    getCartData(state) {
      if (localStorage.getItem("cart")) {
        try {
          state.cart = JSON.parse(localStorage.getItem("cart"));
        } catch (e) {
          localStorage.removeItem("cart");
        }
      }
    },

    // Функция записи корзины в локал
    saveCart(state) {
      const parsed = JSON.stringify(state.cart);
      localStorage.setItem("cart", parsed);
      // console.log("OK - Корзину збережено"); // Выводим сообщение в лог
    },

    // Функция подсчета общей сумы в корзине
    calcTotalCartPrice(state) {
      if (state.cart.length === 0) {
        state.cart_total_price = 0;
      } else {
        state.cart_total_price = 0;
        state.cart.forEach((item) => {
          state.cart_total_price =
            state.cart_total_price + parseInt(item.total_price);
        });
      }
    },

    // удаление товара из корзины
    removeFromCart(state, index) {
      state.cart.splice(index, 1); // удаление товара из корзины
    },

    // Меняем колличество товаров в массиве товаров при изменениеи колличества товаров в корзине
    updateQuntityProductsData(state, product) {
      state.allProductsData.forEach((item) => {
        if (item.id === product.id) {
          item.quantity = product.cart_quantity;
          item.cart_quantity = product.cart_quantity;
          item.total_price = item.quantity * item.price_ua;
        }
      });
    },

    // Обновление кол-ва товаров в поле инпут корзины
    updateQuantityProductInCart(state, product) {
      if (product.cart_quantity >= 1) {
        product.total_price = product.cart_quantity * product.price_ua;
        // Меняем кол-во товаров продукта в каталоге равное кол-ву товаров в корзине
        state.allProductsData.forEach((item) => {
          if (item.id === product.id) {
            item.quantity = product.cart_quantity;
            item.cart_quantity = product.cart_quantity;
            item.total_price = item.quantity * item.price_ua;
          }
        });
      } else {
        // Если введено отрицательное или нулевое зачение находим товар в каталоге и возвращем предыдущие значение
        state.allProductsData.forEach((item) => {
          if (item.id === product.id) {
            product.cart_quantity = item.quantity;
            product.total_price = product.cart_quantity * product.price_ua;
          }
        });
      }
    },

    // Меняем значения товаров которые были удалены из корзины
    updateProductData(state, product) {
      state.allProductsData.forEach((item) => {
        if (item.id === product.id) {
          item.in_cart = false;
          item.quantity = 1;
          item.cart_quantity = 0;
          item.total_price = item.quantity * item.price_ua;
        }
      });
    },

    // Меняем все значения товаров после очистки корзины
    updateProductsData(state) {
      state.allProductsData.forEach((i) => {
        i.in_cart = false;
        i.quantity = 1;
        i.cart_quantity = 0;
        i.total_price = i.quantity * i.price_ua;
      });
    },

    // очистка корзины
    clearCart(state) {
      state.cart = [];
    },

    // Сохраняем данные для страницы контактов
    saveContactPageData(state, data) {
      state.contactPageAboutData = data[0];
      state.contactPageHeroData = data[1];
    },

    // Сохраняем данные всех услуг allServisesData
    saveAllServicesDataAndBlocks(state, data) {
      state.allServisesData = data;
      state.projectData = data[0];
      state.montagData = data[1];
      state.servicData = data[2];
      state.remontData = data[3];
      state.zapuskData = data[4];
      state.zimaData = data[5];
    },

    // Сохраняем данные всех блоков главной страницы
    saveHomePageBlocks(state, data) {
      state.homePageData = data;
      state.homePageHeroBlock = data[0];
      state.homePageServicesBlock = data[1];
      state.homePageProductsBlock = data[2];
      state.homePagePartnersBlock = data[3];
      state.homePageFaqBlock = data[4];
    },

    // Сохраняем данные homePageFaqDate
    saveHomePageFaqDate(state, data) {
      state.homePageFaqDate = data;
    },

    // Сохраняем Инструкции
    saveDocumentation(state, data) {
      state.allDocData = data;
      state.selectDoc = state.allDocData[0].instructions[0];
    },
  },
  actions: {
    addToCart({ commit }, product) {
      commit("addToCart", product); // вызов мутации addToCart для добавления товара в корзину
    },
    removeFromCart({ commit }, index) {
      commit("removeFromCart", index); // вызов мутации removeFromCart для удаления товара из корзины
    },
    clearCart({ commit }) {
      commit("clearCart"); // вызов мутации clearCart для очистки корзины
    },
  },
  modules: {},
});
